import { useSelector } from "react-redux";
import HeaderNavigation from "../blocks/headerNavigation";
import FooterV2 from "../footerV2/footer";
import getComponent from "../shared/util/index";
import { shouldRenderComponent } from "../shared/util/ComponentAuth";
import { useState } from "react";
import CheckboxAgeGate from "../ageGate/checkbox"
import { useEffect } from "react";
function HomePage({ page, slug }) {
  const { isLogin } = useSelector((state) => state.user);
  const [updateAgeGate, setUpdateAgeGate]= useState(true)
  useEffect(()=>{
    // setting age gate for handling 
    if (window.sessionStorage.getItem('ageGate_Value')==='true'){
      setUpdateAgeGate(false)
    }
    if (window.sessionStorage.getItem('ageGate_Value')==='false'){
      setUpdateAgeGate(true)
    }
    
  },[])
  const handleAgeGate = ()=>{
    setUpdateAgeGate(false)
  }
  const retailerConfig = page[0];
  const homePageConfig = page.find(page =>
    page.slug === slug &&
    (isLogin
      ? ['both', 'authenticated'].includes(page.auth_visibility)
      : ['both', 'unauthenticated'].includes(page.auth_visibility))
  );
  const header = retailerConfig.components.find(
    (component) => component.block_type === "HeaderNavigationBlock"
  );
  const footer = retailerConfig.components.find(
    (component) => component.block_type === "FooterBlock"
  );
  const ageGate = retailerConfig.components.find(
    (component) => component.block_type === "AgeGateBlock"
  );
  const backgroundColor = homePageConfig.site_color_background
  ? homePageConfig.site_color_background
  : retailerConfig.site_color_background
  ? retailerConfig.site_color_background
  : '#fff';
  if (!shouldRenderComponent(homePageConfig?.auth_visibility)) {
    return null;
  }
   if(ageGate && updateAgeGate){
    return ( <CheckboxAgeGate
            updateAgeGate={handleAgeGate}
            page={page[0]}
          />)
   }
   if(!ageGate || !updateAgeGate){
    return (
      <div style={{backgroundColor: backgroundColor}}>
        {header && <HeaderNavigation page={retailerConfig} />}
  
        <div id="App-content">
          {homePageConfig &&
            homePageConfig.components.map((blockConfig) => (
              <>
                {getComponent(
                  blockConfig.block_type,
                  homePageConfig,
                  blockConfig,
                  retailerConfig
                )}
              </>
          ))}
        </div>
  
        {footer && <FooterV2 page={retailerConfig} />}
      </div>
    );
  }
}
export default HomePage;