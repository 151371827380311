import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { 
  Box, 
  Button, 
  Typography, 
  Link
} from '@mui/material';
import { styled, ThemeProvider, createTheme } from '@mui/material/styles';
import { useForm } from 'react-hook-form';
import { useLocation, Link as RouterLink, useParams } from 'react-router-dom';
import { getBaseUrl, getFullPath } from '../config/retailerConfig';

const getLoginBlock = (components) => {
  return components.find(component => component.block_type === "LoginBlock") || null;
};

const StyledInput = styled('input')(({ theme, error, hasContent }) => ({
  width: '100%',
  padding: '10px',
  paddingRight: '70px',
  fontFamily: theme.typography.fontFamily,  
  fontSize: '16px',
  border: error && hasContent ? `1px solid ${theme.palette.error.main}` : '1px solid #ccc',
  borderRadius: '4px',
  background: error && hasContent ? theme.palette.error.light : 'inherit',
}));

const StyledLabel = styled('label')(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: '14px',
  lineHeight: '24px',
  color: theme.palette.text.primary,
  fontWeight: 'bold',
  display: 'block',
  marginBottom: '8px',
  '& .required': {
    color: theme.palette.error.main,
  }
}));

const ErrorMessage = styled('p')(({ theme }) => ({
  color: theme.palette.error.main,
  fontFamily: theme.typography.fontFamily,
  fontSize: '14px',
  margin: '4px 0 0',
}));

const ShowHideButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontFamily: theme.typography.fontFamily,
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '24px',
  padding: '0',
  minWidth: 'auto',
  position: 'absolute',
  right: '10px',
  top: '50%',
  transform: 'translateY(-50%)',
  textTransform: 'none',
}));

const ResetPasswordButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  fontFamily: theme.typography.fontFamily,
  padding: '8px 16px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  alignSelf: 'stretch',
  textTransform: 'none',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '24px',
  '&:disabled': {
    backgroundColor: '#ccc',
    color: '#666',
  },
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
  },
}));

const LoginLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
  textAlign: 'center',
  fontFamily: theme.typography.fontFamily,
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '24px',
  textDecoration: 'none',
  textTransform: 'none',
  display: 'block',
  marginTop: '16px',
  padding: '8px',
  borderRadius: '4px',
  transition: 'background-color 0.3s',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  '&:visited': {
    color: theme.palette.primary.main, 
  },
}));

const InputWrapper = styled('div')({
  position: 'relative',
});

const SuccessIcon = styled('img')({
  width: '60px',
  height: '60px',
  marginTop: '16px',
});

const ErrorIcon = styled('img')({
  width: '60px',
  height: '60px',
  marginTop: '16px',
});

const ResetPassword = ({ retailerConfig }) => {
  const { token } = useParams();
  const loginPath = getFullPath('/login');
  const homePath = getFullPath("/home");
  const loginBlock = getLoginBlock(retailerConfig.components);
  const primaryColor = loginBlock?.primary_color || '#6cae3d';

  const theme = createTheme({
    palette: {
      primary: {
        main: primaryColor,
      },
      text: {
        primary: '#212931',
      },
      error: {
        main: '#D82B0C',
        light: '#FBEAE7',
      },
      common: {
        white: '#fff',
      },
      action: {
        hover: '#f5f5f5',
      },
    },
    typography: {
      fontFamily: "Switzer",
    },
  });

  
  const { register, formState: { errors }, handleSubmit, watch, trigger } = useForm({
    mode: 'onChange'
  });
  const password = watch("password", "");
  const confirmPassword = watch("password_repeat", "");

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [isResetSuccessful, setIsResetSuccessful] = useState(false);
  const [isResetError, setIsResetError] = useState(false);

  const location = useLocation();
  const forgotPasswordUrl = `${window.location.origin}${location.pathname.split('/').slice(0, -1).join('/')}/forgot`;

  useEffect(() => {
    const validateForm = async () => {
      const result = await trigger();
      setIsValid(result);
    };
    validateForm();
  }, [password, confirmPassword, trigger]);

  const onSubmit = async data => {
    try {
      const baseUrl = getBaseUrl(retailerConfig.dpn_instance.dpn_instance, true);
      const response = await axios.post(
        `${baseUrl}/user/password/reset`,
        new URLSearchParams({
          resetToken: token,
          updated: data.password
        }),
        {
          headers: {
            'x-retailer': retailerConfig.dpn_instance.dpn_instance,
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      );

      if (response.status === 200) {
        setIsResetSuccessful(true);
        setIsResetError(false);
      } else {
        throw new Error('Password reset failed');
      }
    } catch (error) {
      console.error("Error in form submission:", error);
      setIsResetSuccessful(false);
      setIsResetError(true);
    }
  };

  const togglePasswordVisibility = (field) => {
    if (field === 'password') {
      setShowPassword(!showPassword);
    } else {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          minHeight: '100vh',
          padding: '24px',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '16px',
          maxWidth: '550px',
          margin: '0 auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '16px',
            alignSelf: 'stretch'
          }}
        >
          {loginBlock && loginBlock.logo && (
            <LoginLink component={RouterLink} to={homePath}>
            <Box
              component="img"
              src={loginBlock.logo}
              alt="Logo"
              sx={{
                width: {
                  xs: '180px', 
                  sm: '240px'
                },
                height: 'auto',
              }}
            />
            </LoginLink>
          )}
          {isResetSuccessful && (
            <SuccessIcon src="/images/successIcon.svg" alt="Success" />
          )}
          {isResetError && (
            <ErrorIcon src="/images/errorIcon.svg" alt="Error" />
          )}
          <Typography
            sx={{
              fontSize: {
                xs: '24px',
                sm: '32px',
              },
              lineHeight: '115%',
              color: theme => isResetError ? theme.palette.error.main : theme.palette.text.primary,
            }}
          >
            {isResetSuccessful ? 'Password changed!' : 
             isResetError ? 'Password change failed' : 'Reset Password'}
          </Typography>
          <Typography
            sx={{
              fontSize: '14px',
              lineHeight: '24px',
              textAlign: 'center',
              color: theme => theme.palette.text.primary,
            }}
          >
            {isResetSuccessful
              ? 'Your password has been changed successfully'
              : isResetError
              ? <>
                  Please try again or request a new password reset link.{' '}
                  <Link
                    href={forgotPasswordUrl}
                    sx={{
                      color: theme => theme.palette.primary.main,
                      textDecoration: 'none',
                      '&:hover': {
                        textDecoration: 'none',
                      },
                    }}
                  >
                    Forgot Password
                  </Link>
                </>
              : 'Enter a new password below to change your password'}
          </Typography>
        </Box>
        
        {!isResetSuccessful && !isResetError ? (
          <Box component="form" onSubmit={handleSubmit(onSubmit)} sx={{ width: '100%' }}>
            <Box sx={{ mb: 2 }}>
              <StyledLabel htmlFor="password">New Password <span className="required">*</span></StyledLabel>
              <InputWrapper>
                <StyledInput
                  id="password"
                  type={showPassword ? "text" : "password"}
                  error={!!errors.password}
                  hasContent={!!password}
                  {...register("password", {
                    required: true,
                    validate: (value) => {
                      const passwordRegex = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
                      return passwordRegex.test(value) || "Your password must be at least 8 characters long and include at least 1 uppercase, special character, and a number.";
                    }
                  })}
                />
                <ShowHideButton
                  onClick={() => togglePasswordVisibility('password')}
                >
                  {showPassword ? 'Hide' : 'Show'}
                </ShowHideButton>
              </InputWrapper>
              {errors.password && <ErrorMessage>{errors.password.message}</ErrorMessage>}
            </Box>
      
            <Box sx={{ mb: 2 }}>
              <StyledLabel htmlFor="password_repeat">Confirm Password <span className="required">*</span></StyledLabel>
              <InputWrapper>
                <StyledInput
                  id="password_repeat"
                  type={showConfirmPassword ? "text" : "password"}
                  error={!!errors.password_repeat}
                  hasContent={!!password}
                  {...register("password_repeat", {
                    validate: value => value === password || "The passwords do not match"
                  })}
                />
                <ShowHideButton
                    onClick={() => togglePasswordVisibility('confirm')}
                  >
                    {showConfirmPassword ? 'Hide' : 'Show'}
                </ShowHideButton>
              </InputWrapper>
              {errors.password_repeat && <ErrorMessage>{errors.password_repeat.message}</ErrorMessage>}
            </Box>
      
            <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
              <ResetPasswordButton
                type="submit"
                variant="contained"
                fullWidth
                disabled={!isValid}
              >
              <Typography sx={{fontWeight: '700'}}>
                Reset Password
              </Typography>                
              </ResetPasswordButton>
              <LoginLink component={RouterLink} to={loginPath}>
              <Typography sx={{fontWeight: '700'}}>
                Login
              </Typography>              
              </LoginLink>
            </Box>
          </Box>
        ) : (
          <Box sx={{ width: '100%', mt: 2 }}>
            <ResetPasswordButton
              variant="contained"
              fullWidth
              component={RouterLink}
              to={loginPath}
            >
              <Typography sx={{fontWeight: '700'}}>
                Back to Login
              </Typography>
            </ResetPasswordButton>
          </Box>
        )}
      </Box>
    </ThemeProvider>
  );
};

export default ResetPassword;