import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import { shouldRenderComponent } from "../shared/util/ComponentAuth";

function Button({ component }) {
  const useStyles = makeStyles(() => ({
    btnLink: {
      "&:hover": {
        textDecoration: "none",
      },
      "&:focus-visible": {
        outline: "none",
      },
    },
  }));
  const classes = useStyles();
  if (!shouldRenderComponent(component.auth_visibility)) {
    return null;
  }

  const ButtonBlock = component;

  return (
    <div
      className={
        ButtonBlock.button_grouped === "true" ||
        ButtonBlock.button_grouped === "True"
          ? "btn-group"
          : ""
      }
      role="group"
      aria-label="Basic example"
      style={
        ButtonBlock.button_grouped === "true" ||
        ButtonBlock.button_grouped === "True"
          ? { display: "flex", justifyContent: ButtonBlock?.button_1_position }
          : { textAlign: ButtonBlock.button_1_position }
      }
    >
      {ButtonBlock.button_set.map((item, i) => (
        <button
          tabindex="0"
          type="button"
          key={i}
          alt={item?.button_1_alt}
          style={{
            padding: "0.8rem 1.6rem",
            width: "auto",
            fontSize: "1rem",
            fontFamily: "Switzer",
            color: item?.button_1_text_color,
            backgroundColor: item?.button_1_color,
            boxShadow: "1px 1px 5px 0 rgba(0, 0, 0, 0.2)",
            borderRadius: item?.button_1_border_radius,
            margin:
              ButtonBlock.button_grouped === "true" ||
              ButtonBlock.button_grouped === "True"
                ? "5px 0"
                : "5px",
          }}
          className="chatbot"
        >
          <Link
            to={window.location.href.includes(item?.production_url) ? (item?.is_internal ? `${item?.production_url + "/" + item?.button_1_link}` : item?.button_1_link) : (item?.is_internal ? `${item?.staging_url + "/" + item?.button_1_link}` : item?.button_1_link)}
            className={classes.btnLink}
            style={{ color: item?.button_1_text_color }}
            target={ item?.is_internal ? '_self' : '_blank' }
            rel="noopener noreferrer"
          >
            {item?.button_1_text}
          </Link>
        </button>
      ))}
    </div>
  );
}

export default Button;
