import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import Box from '@mui/material/Box';
import SummaryTable from "./SummaryTable";
import MobileView from "./MobileView";
import { getProgramDetials } from "../service/myloyalty_services";
import { convertDate } from "../shared/util/utils";
import { shouldRenderComponent } from "../shared/util/ComponentAuth";

function RewardsSummaryBlock({ page, retailerConfig }) {
  const { components } = page;

  const { dpn_instance, loyalty_program_id } =
    retailerConfig.retailer_dpn_instance;
  const rewardsSummary = components.filter(
    (item) => item.block_type === "RewardsSummaryBlock"
  );
  const { wallet_url, auth_visibility, title, anchor_id, wallet_button_color, wallet_button_text, wallet_button_text_color, title_text_color } = rewardsSummary[0];
  const [rowsData, setRowsData] = useState([]);
  const emptyMsg = "You have not received any rewards yet";
  const button_border = "1px solid" + wallet_button_text_color;

  const useStyles = makeStyles((theme) => ({
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      gap: "64px",
      width: "100%",
      maxWidth: "1200px",
      margin: "auto",
      padding: "80px 0",

      [theme.breakpoints.down(1201)]: {
        padding: "80px 64px",
      },
      [theme.breakpoints.down(1025)]: {
        padding: "40px 64px",
      },
      [theme.breakpoints.down(769)]: {
        padding: "40px 16px",
      },
      [theme.breakpoints.down("sm")]: {
        overflowX: "hidden",
        gap: "0px",
      },
    },
    header: {
      fontSize: "90px",
      fontWeight: "800",
      fontFamily: "Switzer",
      color: title_text_color,
      lineHeight: "90%",
      letterSpacing: "-0.9px",
      gap: "64px",

      [theme.breakpoints.down("sm")]: {
        fontSize: "36px",
        marginTop: "0px",
        marginBottom: "24px",
        textAlign: "center",
        letterSpacing: "-0.36px",
      },
    },
    desktopView: {
      display: "block",
      width: '100%',
      maxWidth: '1200px',
      [theme.breakpoints.down("sm")]: {
        display: "none !important",
      },
    },
    mobileView: {
      display: "none!important",
      [theme.breakpoints.down("sm")]: {
        display: "block !important",      
      },
    },
    button: {
      display: "flex",
      padding: "16px 48px",
      justifyContent: "center",
      alignItems: "center",
      gap: "16px",
      border: button_border,
      backgroundColor: wallet_button_color,
      borderRadius: "240px",
      width: "Auto",
      height: "auto",
      color: wallet_button_text_color,
      fontSize: "18px",
      fontWeight: "700",
      marginTop: "48px",
      boxShadow:
        "0px 2px 4px 0px rgba(107, 114, 128, 0.12), 0px 3px 5px 0px rgba(107, 114, 128, 0.15)",
      "&:hover": {
        backgroundColor: wallet_button_color,
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: "0px",
        width: "auto",
        padding: "12px 24px",
      },
    },
    emptyMsg: {
        fontFamily: 'switzer',
        fontSize: "18px",
        display: "flex",
        color: "#25272C",
        justifyContent: "center",

        [theme.breakpoints.down("sm")]: {
            marginBottom: "16px",
            fontSize: "16px",
            padding: "0 20px",
        }
      }
  }));
  const classes = useStyles();

  useEffect(() => {
    const rows = [];
    getProgramDetials(
      dpn_instance,
      loyalty_program_id,
      window.localStorage.getItem("retailerToken") || ""
    ).then((data) => {
      const { rewards } = data && data?.rewardGroups[0];
      rewards.map((reward) => {
        const { offer } = reward;
        const expirationDate = offer.expirationDate
          ? convertDate(offer.expirationDate)
          : "- - ";

        if (offer.clippedDates.length > 1 || offer.clippedDates.length > 1) {
          if (offer.clippedDates.length > 1) {          
            let sortedRows = [];
          
            for (let i = 0; i < offer.clippedDates.length; i++) {
              const earnedDate = convertDate(offer.clippedDates[i]);
              const redeemedDate = offer.redeemedDates[i]
                ? convertDate(offer.redeemedDates[i])
                : "- -";
              sortedRows.push(
                createData(
                  offer.shortDescription,
                  earnedDate,
                  redeemedDate,
                  expirationDate
                )
              );
            }
          
            sortedRows.sort((a, b) => new Date(b.earnedDate) - new Date(a.earnedDate));
          
            rows.push(...sortedRows);
          }
        } else {
          const earnedDate = offer.earnedDates[0]
            ? convertDate(offer.earnedDates[0])
            : "- - ";
          const redeemedDate = offer.redeemedDates[0]
            ? convertDate(offer.redeemedDates[0])
            : "- - ";
        }
      });
      setRowsData(rows);
    });
  }, []);

  function createData(desc, earnedDate, redeemedDate, expiredDate) {
    return { desc, earnedDate, redeemedDate, expiredDate };
  }
  if (!shouldRenderComponent(auth_visibility)) {
    return null;
  }
  return (
    <div className={classes.container}>
      <h2 id={anchor_id} className={classes.header} tabIndex={0}>
        {title}
      </h2>

      <div className={classes.desktopView}>
        {rowsData.length > 0 ? (
          <SummaryTable tabIndex={0} rowsData={rowsData} />
        ) : (
          <p className={classes.emptyMsg}>{emptyMsg}</p>
        )}
      </div>

      <div className={classes.mobileView}>
        {rowsData.length > 0 ? (
          <Box sx={{
            border: '1px solid #B6B8C2', 
            borderRadius: '24px', 
            padding: '24px',
            backgroundColor: '#fff',
            marginBottom: '16px'
          }}>
            {rowsData.map((row, index) => {
              return <MobileView tabIndex={0} key={index} row={row} />;
            })}
          </Box>
        ) : (
          <p className={classes.emptyMsg}>{emptyMsg}</p>
        )}
      </div>
      {/* {wallet_url && (
        <button
          className={classes.button}
          onClick={() => window.open(wallet_url)}
        >
          { wallet_button_text }
        </button>
      )} */}
    </div>
  );
}

export default RewardsSummaryBlock;
