import React from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from "@material-ui/core";

function SummaryTable({rowsData}) {
    const useStyles = makeStyles((theme) => ({
        headCell:{
            width: "25%",
            fontSize: '16px!important',
            fontWeight: '700!important',
            borderBottom: "1px solid #B6BAC3",
            fontFamily:"Switzer!important",
            color:"#25272C!important",
            lineHeight:'150%!important'
        },
        tableCell:{
            width: "25%",
            fontSize: '18px!important',
            fontWeight: '400!important',
            borderBottom: "1px solid #B6BAC3",
            fontFamily:"Switzer!important",
            color:"#25272C!important",
            lineHeight:'150%!important',
            border:'0px!important',

        },
        tableHead: {
            backgroundColor: "#fff",
        }
    }))
    
    const testData = [
        {
            desc: "Get $5.00 off any $5.00 purchase or more, courtesy of General Mills",
            earnedDate: "- -",
            redeemedDate: "- -",
            expiredDate: "12/31/2024"
        },
        {
            desc: "Get $5.00 off any $5.00 purchase or more, courtesy of Tillamook",
            earnedDate: "- -",
            redeemedDate: "- -",
            expiredDate: "01/14/2025"
        }
    ];
    const classes = useStyles()
    return (
        <>
       
        <TableContainer sx={{border: '1px solid #B6B8C2', overflow: 'hidden', borderRadius: '24px', padding: '48px', backgroundColor: '#fff' }}>
            <Table aria-label="simple table">
                <TableHead className={classes.tableHead}>
                    <TableRow tabIndex={0}>
                        <TableCell tabIndex={0} className={classes.headCell}>Rewards</TableCell>
                        <TableCell tabIndex={0} className={classes.headCell} align="right">Earned</TableCell>
                        <TableCell tabIndex={0} className={classes.headCell} align="right">Redeemed</TableCell>
                        <TableCell tabIndex={0} className={classes.headCell}  align="right">Expires</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody className={classes.tbody}>
                    {rowsData.map((row, index) => (
                        <TableRow tabIndex={0}
                            key={row.desc}
                            sx={
                                {
                                    '&:last-child td, &:last-child th': { border: 0 }, '&:nth-of-type(even)': {
                                        backgroundColor: '#F8F9FA',
                                    },
                                }
                            }
                        >
                            <TableCell tabIndex={0} component="th" scope="row" 
                            className={classes.tableCell} 
                            >
                                {row.desc}
                            </TableCell>
                            <TableCell tabIndex={0} align="right" 
                            className={classes.tableCell} 
                            >{row.earnedDate}</TableCell>
                            <TableCell tabIndex={0}
                            className={classes.tableCell} 
                            align="right">{row.redeemedDate}</TableCell>
                            <TableCell tabIndex={0} 
                            className={classes.tableCell} 
                            align="right">{row.expiredDate}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>

        </>
    )
}

export default SummaryTable