import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Grid,
  Modal,
  Slide,
  Box,
  makeStyles,
  createTheme,
  useMediaQuery,
} from "@material-ui/core";
import ProfileBody from "../blocks/Account/profile";
import LogoutModal from "../blocks/Account/LogoutModel";
import ChangePassword from "../blocks/Account/ChangePassword";
import HeaderNavigation from "../blocks/headerNavigation";
import FooterV2 from "../footerV2/footer";
import { CLEAR_USER } from "../redux/reducers/user";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import ExitToAppOutlinedIcon from "@material-ui/icons/ExitToAppOutlined";
import { TabPanel, MatTabs, MatTab, a11yProps } from "../utils/matTab";
import { shouldRenderComponent } from "../shared/util/ComponentAuth";
import { getFullPath } from "../config/retailerConfig";
import AccountPageMobile from "./accountPageMobile";

function AccountPage({ page }) {
  const pageConfig = page.find((page) => page.slug === "account");
  const retailerConfig = useSelector((state) => state.config.retailerConfig);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = useState(0);
  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const [openProfileModal, setOpenProfileModal] = useState(false);
  const [openChangePwdModal, setOpenChangePwdModal] = useState(false);

  const backgroundColor = pageConfig.site_color_background
    ? pageConfig.site_color_background
    : retailerConfig.site_color_background
    ? retailerConfig.site_color_background
    : "#fff";

  const header = retailerConfig.components.find(
    (component) => component.block_type === "HeaderNavigationBlock"
  );
  const footer = retailerConfig.components.find(
    (component) => component.block_type === "FooterBlock"
  );
  const colorTheme = retailerConfig.brand_design_theme;
  const signupConfig = retailerConfig.components.find(
    (component) => component.block_type === "SignupBlock"
  );
  const useStyles = makeStyles((theme) => ({
    root: {
      marginTop: "3em",
    },
    container: {
      margin: 0,
      maxWidth: "100%",
      [theme.breakpoints.up("sm")]: {
        margin: `${theme.spacing(1)}px ${theme.spacing(1)}px`,
        maxWidth: "1200px",
        margin: "0 auto !important"
      },
      // Mobile & mini ipad < 600px
      [theme.breakpoints.down("xs")]: {
        padding: "10px",
        height: "100vh",

        "& .tabPanelSection": {
          display: "none",
        },
      },
      [theme.breakpoints.up("md")]: {
        margin: `${theme.spacing(0)}px ${theme.spacing(1)}px`,
        padding: `0 ${theme.spacing(0)}px`,
      },
      [theme.breakpoints.up("lg")]: {
        margin: `${theme.spacing(2)}px ${theme.spacing(1)}px ${theme.spacing(
          3
        )}px
        ${theme.spacing(2)}px`,
      },
      [theme.breakpoints.up("1800")]: {
        margin: `0`,
      },
      [theme.breakpoints.up("xl")]: {
        margin: `${theme.spacing(3)}px ${theme.spacing(2)}px ${theme.spacing(
          3
        )}px
        ${theme.spacing(5)}px`,
      },
    },
    headSection: {
      marginBottom: "24px",

      "&.title $element": {
        fontSize: "20px",
      },
      "& .sub-title": {
        fontSize: "16px",
        opacity: 0.6,
      },
      "& $title": {
        marginTop: 30,
      },

      [theme.breakpoints.down("xs")]: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",

        "& .headSection": {
          display: "none",
        },
      },
    },
    titleCtn: {
      fontFamily: "Switzer",
      fontSize: "18px",
      lineHeight: "27px",
      margin: "5px 0",
      color: colorTheme?.text_color,
    },
    muiTab: {
      maxWidth: "300px",
      "& button": {
        borderRadius: "8px",
        outline: "none",
        boxShadow: "none",
        paddingTop: "0px",
        paddingBottom: "0px",
        margin:'4px 0'
      },
      "& .MuiTab-root": {
        color: colorTheme?.text_color,
      },
      "& .Mui-selected": {
        color: "#212931",
      },
      "& .MuiSvgIcon-root": {
        marginRight: "5px",
        width: "24px",
      },
      "& .MuiTab-wrapper": {
        flexDirection: "row",
        justifyContent: "flex-start",
        letterSpacing: "normal",
        whiteSpace: "nowrap",
        "&> :first-child": {
          marginBottom: "3px",
        },
      },
    },
  }));
  const accountBtnColor = signupConfig.primary_color || colorTheme?.button_background_color;

  const tabList = [
    {
      label: "MyAccount.profile",
      value: "Profile",
      icon: <PermIdentityOutlinedIcon />,
    },
    {
      label: "MyAccount.password",
      value: "Password",
      icon: <LockOutlinedIcon />,
    },
    {
      label: "MyAccount.signout",
      value: "Sign Out",
      icon: <ExitToAppOutlinedIcon />,
    },
  ];

  const theme = createTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const classes = useStyles();

  if (!shouldRenderComponent(pageConfig?.auth_visibility)) {
    return "Please check the setup.";
  }

  const onTabChangeHandler = (tab, idx) => {
    if (isMobile) {
      switch (tab.value) {
        case "Profile":
          setTabValue(idx);
          setOpenProfileModal(true);
          break;
        case "Password":
          setTabValue(idx);
          setOpenChangePwdModal(true);
          break;
        case "Sign Out":
          setOpenLogoutModal(true);
          break;
      }
    } else {
      tab.value === "Sign Out" ? setOpenLogoutModal(true) : setTabValue(idx);
    }
  };

  const onLogout = () => {
    localStorage.clear();
    dispatch(CLEAR_USER());
    navigate(getFullPath("home"));
  };
  return (
    <div style={{ backgroundColor: backgroundColor }}>
      {header && <HeaderNavigation page={retailerConfig} />}
      {/* for mobile  */}
      {isMobile && (
        <AccountPageMobile id="App-content" page={page}></AccountPageMobile>
      )}
      {/* for desktop */}
      {!isMobile && (
        <div id="App-content">
          {pageConfig && (
            <Container
              classes={{
                maxWidthLg: classes.container,
                maxWidthMd: classes.container,
              }}
            >
              <Grid className={classes.root} container item xs={12}>
                <Grid item xs={isMobile ? 12 : 4}>
                  <MatTabs
                    className={classes.muiTab}
                    value={tabValue}
                    orientation="vertical"
                  >
                    {tabList.map((tab, idx) => (
                      <MatTab
                        style={{
                          backgroundColor:
                            tabValue === idx
                              ? colorTheme?.tab_background_color
                              : "transparent",
                        }}
                        label={tab.value}
                        onClick={() => onTabChangeHandler(tab, idx)}
                        retailer={retailerConfig}
                        icon={tab.icon}
                        iconPosition="start"
                        tabIndex="0"
                        {...a11yProps(idx + 1)}
                      />
                    ))}
                  </MatTabs>
                </Grid>

                <Grid className="tabPanelSection" item xs={8}>
                  <TabPanel value={tabValue} index={0}>
                    <div className="profile-v3-ctn">
                      <ProfileBody
                        retailer={retailerConfig}
                        isMobile={isMobile}
                      />
                    </div>
                  </TabPanel>

                  <TabPanel value={tabValue} index={1}>
                    <div className="profile-v3-ctn">
                      <ChangePassword
                        retailer={retailerConfig}
                        isMobile={isMobile}
                      />
                    </div>
                  </TabPanel>
                </Grid>
              </Grid>

              {openProfileModal && (
                <Modal
                  open={openProfileModal}
                  className="v2-modal"
                  onClose={() => setOpenProfileModal(false)}
                  aria-labelledby="filters-modal"
                >
                  <Slide direction="left" in={openProfileModal} timeout={1000}>
                    <Box
                      style={{
                        height: "100%",
                        backgroundColor: backgroundColor,
                      }}
                    >
                      <ProfileBody
                        retailer={retailerConfig}
                        isMobile={isMobile}
                        onClose={() => setOpenProfileModal()}
                      />
                    </Box>
                  </Slide>
                </Modal>
              )}

              {openChangePwdModal && (
                <Modal
                  open={openChangePwdModal}
                  className="v2-modal"
                  onClose={() => setOpenChangePwdModal(false)}
                  aria-labelledby="filters-modal"
                >
                  <Slide
                    direction="left"
                    in={openChangePwdModal}
                    timeout={1000}
                  >
                    <Box
                      style={{
                        height: "100vh",
                        backgroundColor: backgroundColor,
                      }}
                    >
                      <ChangePassword
                        retailer={retailerConfig}
                        isMobile={isMobile}
                        onClose={() => setOpenChangePwdModal(false)}
                      />
                    </Box>
                  </Slide>
                </Modal>
              )}

              {openLogoutModal && (
                <LogoutModal
                  open={openLogoutModal}
                  mainColor={colorTheme?.button_background_color}
                  mainColorHover={colorTheme?.button_background_color}
                  logout={() => onLogout()}
                  closeModal={() => setOpenLogoutModal(false)}
                />
              )}
            </Container>
          )}
        </div>
      )}

      {footer && <FooterV2 page={retailerConfig} />}
    </div>
  );
}

export default AccountPage;
