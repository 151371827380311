import React, { useEffect, useState } from "react";
import {
  Grid,
  makeStyles,
  useMediaQuery,
  createTheme,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { getProgramDetials } from "../../service/myloyalty_services";
import { ReactComponent as SearchIcon } from "../../assets/images/svg/lockTag.svg";

export function SpendablePointsProgramGrid(props) {
  const blockConfig = props.page.components.find(
    (component) => component.block_type === "SpendablePointsGridBlock"
  );
  const siteConfig = useSelector((state) => state.config.retailerConfig);

  const theme = createTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const [loyaltyProgram, setLoyaltyProgram] = useState();
  const [error, setError] = useState(null);

  const useStyles = makeStyles((theme) => ({
    rootCtn: {
      maxWidth: "1200px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      margin: "0 auto",
    },
    titleSec: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",

      "& .pageSubTitle": {
        fontSize: "60px",
        fontFamily: "switzer",
        fontWeight: "600",
        margin: "12px 0",
        letterSpacing: "1px",
      },

      "& .ptsLabel": {
        fontSize: "28px",
        fontWeight: "700",
        lineHeight: "30.8px",
      },

      // Ipad
      [theme.breakpoints.down("md")]: {
        padding: "0 12px",

        "& .pageSubTitle": {
          fontSize: "32px",
        },

        "& .ptsLabel": {
          fontSize: "24px",
        },
      },

      // Mobile
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        alignItems: "flex-start",
        padding: "20px 10px",

        "& .pageSubTitle": {
          margin: "0",
          fontSize: "30px",
        },

        "& .ptsLabel": {
          fontSize: "16px",
        },
      },
    },
    rewardsGrid: {
      display: "grid",
      gridTemplateColumns: "repeat(3, 1fr)",

      [theme.breakpoints.down("xs")]: {
        gridTemplateColumns: "repeat(2, 1fr)",
      },
    },
    rewardItem: {
      borderRight: "1px solid #CCD1D7",
      borderBottom: "1px solid #CCD1D7",
      display: "flex",
      justifyContent: "center",
      padding: "24px",

      "& .rewardImg": {
        width: "352px",
        height: "352px",

        [theme.breakpoints.down("md")]: {
          width: "180px",
          height: "180px",
        },

        [theme.breakpoints.down("xs")]: {
          width: "140px",
          height: "140px",
        },
      },

      "& .lockIcon": {
        position: "absolute",
        right: "-8px",
        bottom: "-30px",

        [theme.breakpoints.down("md")]: {
          "& svg": {
            width: "80px",
          },
        },

        [theme.breakpoints.down("xs")]: {
          right: "-2px",
          bottom: "-48px",

          "& svg": {
            width: "54px",
          },
        },
      },

      "& .rewardInfoSection": {
        width: "100%",

        "& .title": {
          marginTop: "1rem",
          marginBottom: "0",
          fontWeight: "700",
          lineHeight: "19.8px",
          fontFamily: "switzer",
          fontSize: "18px",

          [theme.breakpoints.down("xs")]: {
            fontSize: "16px",
          },
        },

        "& .desc": {
          fontWeight: "400",
          lineHeight: "24px",
          fontFamily: "switzer",
          fontSize: "16px",
          marginTop: "4px",

          [theme.breakpoints.down("xs")]: {
            fontSize: "14px",
          },
        },
      },
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    getLoyaltyProgramDetails();
  }, []);

  const getLoyaltyProgramDetails = async () => {
    const response = await getProgramDetials(
      siteConfig.dpn_instance.dpn_instance,
      siteConfig.dpn_instance.loyalty_program_id,
      localStorage.getItem("token")
    );
    if (!response.error) {
      if (response) {
        setLoyaltyProgram(response);
      }
    } else {
      setError("Error");
    }
  };

  const showRewardLock = (program) => {
    if (program.rewards && program.rewards.length === 1) {
      return (
        loyaltyProgram?.lifetimePoints >= program.targetThreshold &&
          new Date(program.rewards[0].offer.expirationDate) >= new Date() 
      );
    }

    return false;
  };

  // These are the fields we are using to define the reward
  // Short Description - Item description + Size ( Free Labatt Men’s Tshirt- Size L)
  // Long Description - Item description  ( Free Labatt Men’s Tshirt)
  // Legal Terms - size (L, M, XL)
  return (
    <div className={classes.rootCtn}>
      <div className={classes.titleSec} item md={12} xs={12}>
        <div className="pageSubTitle">{blockConfig?.title}</div>
        {loyaltyProgram && (
          <div className="ptsLabel">
            Point balance:
            <span style={{ fontWeight: isMobile ? "600" : "400" }}>
              &nbsp;{loyaltyProgram?.lifetimePoints} Points
            </span>
          </div>
        )}
      </div>

      <div className={classes.rewardsGrid}>
        {loyaltyProgram?.rewardGroups?.length > 0 &&
          loyaltyProgram.rewardGroups.map((program, idx) => (
            <Grid
              container
              direction="row"
              item
              justifyContent="flex-start"
              alignItems="stretch"
              className={classes.rewardItem}
              style={
                !isMobile
                  ? {
                      borderRight:
                        (idx + 1) % 3 === 0 ? "0" : "1px solid #CCD1D7",
                    }
                  : {}
              }
            >
              <div style={{ position: "relative" }}>
                <img
                  className="rewardImg"
                  src={
                    program.rewards[0].offer.enhancedImageUrl
                      ? program.rewards[0].offer.enhancedImageUrl
                      : program.rewards[0].offer.imageUrl
                  }
                />
                <div className="lockIcon">
                  {!showRewardLock(program) && (
                    <SearchIcon className="svgIcon" />
                  )}
                </div>
              </div>

              <div className="rewardInfoSection">
                <label className="title">
                  {program.targetThreshold} Points {idx % 3}
                </label>
                <p className="desc">
                  {program.rewards[0].offer.shortDescription}
                </p>
              </div>
            </Grid>
          ))}
      </div>
    </div>
  );
}

export default SpendablePointsProgramGrid;
