export const formFieldMap = (data) => {
  let fieldName;
  if (data.fieldMap) fieldName = data.fieldMap;
  else {
    if (data.label === "Phone Number") {
      fieldName = "phone";
    }
    if (data.label === "Email Address") {
      fieldName = "email";
    }
  }
  return fieldName;
};

// Grid values changing based on the fields.
export const handleMultiColumn = (data, isMobile) => {
  let xs = "";
  if (isMobile) {
    xs = 12;
  } else if (
    data.name === "birthMonth" ||
    data.name === "birthDay" ||
    data.name === "birthYear"
  ) {
    xs = 4;
  } else {
    xs = 12;
  }
  return xs;
};

// Changing the field label based on the config language
export const getFieldLabel = (fields, locale) => {
  let newLabel = "";
  if (Object.keys(fields).indexOf("internationalization") > -1) {
    newLabel =
      fields.internationalization[locale] !== undefined
        ? fields.internationalization[locale]
        : fields.label;
  } else if (fields.name === "birthMonth") {
    newLabel = "Date of birth";
  } else if (fields.name === "birthDay" || fields.name === "birthYear") {
    newLabel = "";
  } else {
    newLabel = fields.label;
  }
  return newLabel;
};

// Checking the required field  based on the config fields
export const mandatoryField = (fields) => {
  const validation =
    fields.name === "birthYear" || fields.name === "birthDay"
      ? false
      : fields.isRequired || fields.required;
  return validation;
};

// Adding the classe base on the device and errors
export const handleUsernameClass = (fieldValue, errors, classes) => {
  let txtFieldValue;
  if (errors[fieldValue]) txtFieldValue = classes.errortextfield;
  else txtFieldValue = classes.textField;
  return txtFieldValue;
};

// Adding the classe base on the device and errors
export const handleSelectClass = (fieldValue, errors, classes) => {
  let txtFieldValue;
  if (errors[fieldValue]) txtFieldValue = classes.selectBoxerror;
  else txtFieldValue = classes.selectBox;
  return txtFieldValue;
};

export const handleEditablefield = (editable, fieldCheck) => {
  let editText;
  if (!editable) {
    editText = true;
  } else {
    if (fieldCheck) editText = false;
    else editText = true;
  }
  return editText;
};
