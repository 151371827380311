import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchBar from "../shared/util/components/searchComponent";

function FAQBlock({ component }) {
  const [textFieldValue, setTextFieldValue] = useState("");
  const [faqQuestions, setFaqQuestions] = useState(component.faq_set);

  const useStyles = makeStyles((theme) => ({
    faqContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      margin: "0 auto",
      backgroundColor: component.background_color,
      maxWidth: "1200px",
      paddingBottom: "80px",
      paddingTop: "80px",
      [theme.breakpoints.down(1025)]: {
        paddingBottom: "40px",
        paddingTop: "40px",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "40px 0.6rem",
      },
    },
    header: {
      fontFamily: "switzer",
      fontStyle: "normal",
      fontSize: "5.625rem",
      color: component.text_color,
      lineHeight: "1",
      fontWeight: "800",
      letterSpacing: "-0.01em",
      textWrap: "balance",
      textAlign: "center",

      [theme.breakpoints.down("sm")]: {
        fontSize: "64px",
      },

      [theme.breakpoints.down("xs")]: {
        fontSize: "36px",
      },
    },
    searchBarContainer: {
      width: "100%",
      padding: "0",
      [theme.breakpoints.down("lg")]: {
        padding: "0 32px",
      },
      [theme.breakpoints.down("sm")]: {
        padding: "0 32px",
      },
      [theme.breakpoints.down("xs")]: {
        padding: "0 16px",
      },
    },

    searchBar: {
      alignItems: "center",
      display: "flex",
      margin: "48px 0 0 0",
      borderRadius: "10px",
      background: "transparent",
      width: "100%",
      padding: "16px",
      backgroundColor: "#FFFFFF",
      [theme.breakpoints.down("xs")]: {
        margin: "32px 0 0 0",
        padding: "11px",
      },

      "& .MuiInputBase-input": {
        color: component.text_color,
        fontSize: "1.125rem",
        fontFamily: "switzer",

        [theme.breakpoints.down("xs")]: {
          fontSize: "14px !important",
        },
      },
      [theme.breakpoints.down("xs")]: {
        padding: "11px",
      },

      "& .svgIcon": {
        fill: component.color_chevron,
        height: "30px",
        width: "30px",

        [theme.breakpoints.down("xs")]: {
          height: "27px",
          width: "27px",
        },
      },
    },
    faqContent: {
      width: "100%",
      marginTop:'48px',
      [theme.breakpoints.down("lg")]: {
        padding: "0 32px",
      },
      [theme.breakpoints.down("sm")]: {
        marginTop: "32px",
        padding: "0 32px",
      },
      [theme.breakpoints.down("xs")]: {
          padding: "0 16px",
      },

      "& .MuiAccordion-root": {
        boxShadow: "none",
        background: "transparent",
        borderBottom: "1px solid #b6bac3",
        borderBottomLeftRadius: "0 !important",
        borderBottomRightRadius: "0 !important",
        margin: "0 !important",

        "&:nth-child(1)": {
          "& .MuiAccordionSummary-content": {
            paddingTop: "0",
          },

          "& .Mui-expanded .MuiAccordionSummary-root": {
            margin: "0 !important",
            padding: "0 0 8px",
          },

          "& .MuiAccordionSummary-expandIconWrapper": {
            marginTop: "-38px",

            [theme.breakpoints.down("sm")]: {
              marginTop: "-34px",
            },
          },

          "& .Mui-expanded .MuiAccordionSummary-expandIconWrapper": {
            marginTop: "-16px",

            [theme.breakpoints.down("sm")]: {
              marginTop: "-30px",
              
            
            },
          },
        },

        "& .MuiSvgIcon-root": {
          color: component.color_chevron,
          fontSize: "2rem",
        },
        "& .MuiAccordionSummary-root": {
          padding: "0",
          minHeight: "auto",
        },
        "& .MuiAccordionDetails-root": {
          padding: "0",
        },
        "& .MuiAccordionDetails-root": {
          minHeight: "auto",
          padding: "0",
        },
        "& .MuiAccordionSummary-content": {
          padding: "48px 0",
          fontSize: "1.125rem",
          fontWeight: "700",
          fontFamily: "switzer",
          lineHeight: "1.5",
          color: component.text_color,
          margin: "0",

          "&:before": {
            backgroundColor: "#fff",
          },

          [theme.breakpoints.down("sm")]: {
            fontSize: "16px",
            padding: "32px 0",
          },
        },

        "& .Mui-expanded .MuiAccordionSummary-content": {
          paddingBottom: "16px",
        },

        "& .Mui-expanded .MuiAccordionSummary-expandIconWrapper": {
          marginTop: "28px",

          [theme.breakpoints.down("sm")]: {
            marginTop: "20px",
          },
        },
      },
    },
    cardBody: {
      color: component.text_color,
      fontSize: "1.125rem",
      fontFamily: "switzer",
      lineHeight: "1.5",
      fontWeight: "400",

      "& p:last-child": {
        marginBottom: "48px",
      },

      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",

        "& p:last-child": {
          marginBottom: "38px",
        },
      },
    },
  }));
  const classes = useStyles();

  const trackFAQEvent = (faq_question) => {
    // Track the event using Google Analytics or other tracking tools
    window.gtag("event", "Expand_FAQ", {
      question: faq_question.question,
      version: localStorage.getItem("AB_TEST_VARIABLE") || "N/A",
    });
  };

  const onSearchHandlear = () => {
    if (textFieldValue) {
      let faq = faqQuestions;
      setFaqQuestions(
        faq.filter((faq) => {
          return (
            faq.question.toLowerCase().includes(textFieldValue.toLowerCase()) ||
            faq.answer.toLowerCase().includes(textFieldValue.toLowerCase())
          );
        })
      );

      // Track the event using Google Analytics
      window.gtag("event", "Search_FAQ", {
        text: textFieldValue,
        version: localStorage.getItem("AB_TEST_VARIABLE") || "N/A",
      });
    } else {
      setFaqQuestions(component.faq_set);
    }
  };

  return (
    <div className={classes.faqContainer}>
      <div className={classes.header}>{component.faq_title}</div>
      {/* showing and hiding search bar for different FAQ modules */}
      {component.show_searchBar && (
        <div className={classes.searchBarContainer}>
          <SearchBar
            value={textFieldValue}
            placeholder="Search"
            color={component.color_chevron}
            onChange={(newValue) => {
              setTextFieldValue(newValue);
              if (!newValue) {
                setFaqQuestions(component.faq_set);
              }
            }}
            onCancelResearch={() => setTextFieldValue("")}
            className={classes.searchBar}
            onSearch={() => onSearchHandlear()}
          />
        </div>
      )}

      <div className={classes.faqContent}>
        {faqQuestions.map((faq, idx) => (
          <>
            <Accordion
              id={`faq-${idx}`}
              onChange={(e, expanded) => {
                if (expanded) {
                  trackFAQEvent(faq);
                }
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon fontSize="large" />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                {faq.question}
              </AccordionSummary>
              <AccordionDetails>
                <div
                  style={{ textAlign: "start" }}
                  className={classes.cardBody}
                  dangerouslySetInnerHTML={{ __html: faq.answer }}
                />
              </AccordionDetails>
            </Accordion>
          </>
        ))}

        {faqQuestions.length === 0 && (
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: "18px",
              fontWeight: "600",
            }}
          >
            No FAQs match your search criteria.
          </p>
        )}
      </div>
    </div>
  );
}

export default FAQBlock;
