import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  FormControl,
  FormHelperText,
  TextField,
  InputAdornment,
  makeStyles,
  MenuItem,
  Select,
  useMediaQuery,
  createTheme,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import ArrowDropDownOutlinedIcon from "@material-ui/icons/ArrowDropDownOutlined";
import ErrorOutlineOutlinedIcon from "@material-ui/icons/ErrorOutlineOutlined";
import {
  updateUserCall,
  updateUserSuccess,
} from "../../../service/account_service";
import {
  formErrorHandling,
  formFieldsConfig,
  stateFieldConfig,
} from "../../../shared/util/UserValidations";
import {
  birthMonth,
  birthDay,
  birthYear,
  stateList,
} from "../../../utils/state";
import PopUpModal from "../../../shared/util/components/dialogComponent";
import {
  formFieldMap,
  handleMultiColumn,
  getFieldLabel,
  mandatoryField,
  handleUsernameClass,
  handleSelectClass,
  handleEditablefield,
} from "../AccountUtils";

function ProfileWrapper(props) {
  const { retailerName, fields, editField, setCancel } = props;
  const { user } = useSelector((state) => state);
  const stateData = useState(stateList);
  const signupConfig = props.config;
  const theme = createTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
  const retailerFormFields = formFieldsConfig(props.fields, props.formType);
  const [isLoading, setLoading] = useState(false);

  const errorIcon = <ErrorOutlineOutlinedIcon style={{ fill: "#ff0000" }} />;
  const dropdownIcon = <ArrowDropDownOutlinedIcon />;

  const dispatch = useDispatch();

  const [formValues, setFormValues] = useState(
    stateFieldConfig(retailerFormFields, "", props.formType)
  );
  const [openPopUpModal, setOpenPopUpModal] = useState(false);
  const [popUpText] = useState("");
  const [errors, setFormErrors] = useState(
    stateFieldConfig(retailerFormFields, "error")
  );
  const [formError, setFormError] = useState("");
  const [successMsg, setSuccessMsg] = useState(null);
  const [profileUpdErrMsg, setProfileUpdErrMsg] = useState(null);
  const colorTheme = props.siteConfig.brand_design_theme;

  const useStyles = makeStyles((theme) => ({
    margin: {
      display: "flex",
      margin: theme.spacing(1),
      fontSize: "16px",
    },
    marginMobile: {
      display: "flex",
      fontSize: "16px",
    },
    textField: {
      backgroundColor: "#fff",
      borderRadius: "8px",
      fontSize: "18px",
      "&>div": {
        fontSize: "18px",
        borderRadius: "8px",
      },
      "& input": {
        padding: "16px 16px",
        minHeight: isMobile ? "18px" : "27px",
        borderRadius: "8px",
      },
      // Mobile css
      [theme.breakpoints.down("sm")]: {
        "&>div": {
          fontSize: "16px",
        },
      },
      [theme.breakpoints.down("xs")]: {
        "&>div": {
          fontSize: "14px",
        },
      },
    },
    signInbtn: {
      display: "flex",
      alignSelf: "flex-start",
      height: "52px",
      width: isMobile ? "120px" : "139px",
      padding: "16px 48px",
      background:
        signupConfig.primary_color || colorTheme?.button_background_color,
      boxShadow: "0px 3px 5px rgba(107, 114, 128, 0.15)",
      borderRadius: 240,
      gap: 16,
      color:
        signupConfig.button_text_color || colorTheme?.primary_button_text_color,
      fontSize: "18px",
      fontFamily: "Switzer",
      fontWeight: "700",
      lineHeight: "19.8px",
      textTransform: "none",
      marginTop: "8px",
      marginLeft: "8px",
      "&:hover": {
        background:
          signupConfig.primary_color || colorTheme?.button_background_color,
        cursor: "default",
      },
      "&:focus": {
        background:
          signupConfig.primary_color || colorTheme?.button_background_color,
        cursor: "default",
        outline: "none",
      },
      "&:active": {
        background:
          signupConfig.primary_color || colorTheme?.button_background_color,
        cursor: "default",
      },
    },
    cancelBtn: {
      display: "flex",
      alignSelf: "flex-start",
      width: "fit-content",
      height: "100%",
      padding: isMobile ? "16px 24px" : "16px 48px",
      gap: 16,
      color: colorTheme?.text_color,
      fontSize: "18px",
      fontFamily: "Switzer",
      fontWeight: "700",
      lineHeight: "19.8px",
      textTransform: "none",
      marginTop: "8px",
      marginLeft: "8px",
      cursor: "pointer",
    },
    updateProfileBtn: {
      width: "100%",
      display: "flex",
      justifyContent: "left",
      flexWrap: "wrap",
      flexDirection: "row !important",
      alignItems: "flex-end",
      paddingBottom: "10px",
      margin: "24px 0",
    },
    loyaltyId: {
      fontSize: "18px",
      fontFamily: "Switzer",
      color: colorTheme?.text_color,
      fontWeight: 700,
      marginLeft: 0,
      lineHeight: isMobile ? "25px" : "32px",
      marginRight: 0,
      // Mobile css
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
        lineHeight: "25px",
        marginTop: "5px",
        "& .MuiInputBase-input": {
          fontSize: "14px",
        },
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "14px",
        lineHeight: "25px",
        marginTop: "10px",
        "& .MuiInputBase-input": {
          fontSize: "14px",
        },
      },
    },
    labelText: {
      fontSize: "18px",
      fontFamily: "Switzer",
      color: colorTheme?.text_color,
      fontWeight: 400,
      marginLeft: 0,
      lineHeight: isMobile ? "25px" : "27px",
      // Mobile css
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "14px",
      },
    },
    mandatoryField: {
      color: "red",
      fontFamily: "Switzer",
    },
    errorMessage: {
      fontSize: "14px",
      fontWeight: "400",
      marginLeft: "10px",
      color: "#D82B0C",
      fontStyle: "normal",
    },
    errorMobile: {
      fontSize: "14px",
      fontWeight: "400",
      marginLeft: "12px",
      color: "#D82B0C",
      fontStyle: "normal",
    },
    errortextfield: {
      borderRadius: "4px",
      background: "#FBEAE7",
      fontSize: "16px",
      "&>div": {
        fontSize: "16px",
        height: "48px",
      },
      "& input": {
        padding: "14.5px 14px",
        WebkitBoxShadow: "0 0 0 1000px #FBEAE7 inset",
      },
    },

    unselectedValueBox: {
      borderRadius: "8px",
      backgroundColor: "transparent",
      padding: "16px 16px",
      height: "52px",
      width: isMobile ? "90%" : "100%",
      color: colorTheme?.text_color,
      "& .MuiSelect-select": {
        backgroundColor: "transparent !important",
        color: colorTheme?.text_color,
      },
      "&.Mui-focused .MuiSelect-select": {
        backgroundColor: "transparent",
        color: colorTheme?.text_color,
      },
      "& .MuiSelect-select:hover": {
        backgroundColor: "transparent",
        color: colorTheme?.text_color,
      },
      "& .MuiSelect-select:active": {
        backgroundColor: "transparent",
        color: colorTheme?.text_color,
      },
    },
    selectValueBox: {
      backgroundColor: "#fff",
      borderRadius: "8px",
      fontSize: "16px",
      padding: "16px 16px",
      textAlign: "left",
      height: "52px",
      width: isMobile ? "90%" : "100%",
      "&> :first-child": {
        padding: "4.5px 0",
        fontSize: "16px",
        textAlign: "left",
        minWidth: "100%",
        "&:focus": {
          backgroundColor: "#fff",
        },
      },
    },
    selectPlaceholder: {
      textAlign: "left",
      fontSize: "16px",
      color: `${colorTheme?.text_color} !important`,
      fontStyle: "normal",
      fontWeight: "400",
      marginTop: "31px",
      opacity: "0.5",
      transform: "translate(14px, 20px) scale(1) !important",
    },
    selectIcon: {
      color: colorTheme?.text_color,
      position: "absolute",
      right: "14px",
      cursor: "pointer",
      pointerEvents: "none",
    },
    selectValueicon: {
      color: colorTheme?.text_color,
      position: "absolute",
      right: "14px",
      cursor: "pointer",
      pointerEvents: "none",
    },

    alertBoxerror: {
      display: "flex",
      alignItems: "center",
      padding: "1.4rem 1.6rem",
      borderRadius: "8px",
      color: " #561104",
      background: "#FBEAE7",
      border: "1px solid #D82B0C",
      fontSize: "16px",
      width: "94%",
      marginTop: "14px",
      marginLeft: "10px",
    },
    alertBoxsucesserror: {
      display: "flex",
      alignItems: "center",
      padding: "1.4rem 1.6rem",
      borderRadius: "8px",
      color: " #44a047",
      background: "#5d9d5133",
      border: "1px solid #44a047",
      fontSize: "16px",
      width: "94%",
      marginTop: "14px",
      marginLeft: "10px",
    },
    alertBoxsucessMobile: {
      display: "flex",
      alignItems: "center",
      padding: "1.4rem 1.6rem",
      borderRadius: "8px",
      color: " #44a047",
      background: "#5d9d5133",
      border: "1px solid #44a047",
      fontSize: "16px",
      width: "96%",
      marginLeft: "4px",
      marginTop: "10px",
    },
    alertBoxMobile: {
      display: "flex",
      alignItems: "center",
      padding: "1.4rem 1.6rem",
      borderRadius: "8px",
      color: " #561104",
      background: "#FBEAE7",
      border: "1px solid #D82B0C",
      fontSize: "16px",
      width: "96%",
      margin: "10px 0 10px 4px",
    },

    contactBtn: {
      textAlign: "right",
      marginBottom: "12px",
      marginTop: "10px",
      fontSize: "18px",
      fontWeight: 700,
      fontFamily: "Switzer",
      lineHeight: "19.8px",
      color: `${colorTheme?.text_color} !important`,
      textDecoration: "none !important",
      // Mobile css
      [theme.breakpoints.down("sm")]: {
        fontSize: "16px",
        marginTop: "12px",
        marginBottom: "8px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "14px",
        marginTop: "12px",
        marginBottom: "8px",
      },
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    // Set user values to profile page
    // Take cache user form cache to reduce delay.
    setLoading(true);
    const cachedUser = JSON.parse(localStorage.getItem("userData"))
      ? JSON.parse(localStorage.getItem("userData"))
      : user;
    setFormValues(
      stateFieldConfig(retailerFormFields, "", props.formType, cachedUser)
    );
    setLoading(false);
  }, [user]);

  const handleCancel = () => {
    setCancel();
    setLoading(true);
    const cachedUser = JSON.parse(localStorage.getItem("userData"))
      ? JSON.parse(localStorage.getItem("userData"))
      : user;
    setFormValues(
      stateFieldConfig(retailerFormFields, "", props.formType, cachedUser)
    );
    setLoading(false);
  };

  // Input filed values assigning
  const handleChange = (prop, field) => (event) => {
    setSuccessMsg(null);
    setProfileUpdErrMsg(null);
    let value = event.target.value;
    let typeCheck = false;
    let lengthCheck = false;
    if (
      prop === "phone" ||
      field.fieldMap === "phone" ||
      field.label === "Phone Number" ||
      prop === "loyaltyId" ||
      field.fieldMap === "loyaltyId"
    ) {
      typeCheck = isNaN(value);
      lengthCheck = 10 < value.length;
    }

    if (!typeCheck && !lengthCheck)
      setFormValues({ ...formValues, [prop]: value });
    setFormErrors({ ...errors, [prop]: "" });
  };

  // Drop down  values Changing
  const handleSelectChange = (prop, community) => (event) => {
    const value = event.target.value;
    setFormValues({
      ...formValues,
      [prop]: community ? event.target.getAttribute("name") : value,
    });
    setFormErrors({ ...errors, [prop]: "" });
    setSuccessMsg(null);
    setProfileUpdErrMsg(null);
  };

  // Create Account Click functionality
  const handleCreate = async () => {
    setSuccessMsg(null);
    setFormErrors(null);
    let formerrors = errors;
    retailerFormFields.map((data) => {
      const validationCheck =
        (data.isRequired || data.required) && !data.disabled;
      if (validationCheck && formValues[data.name] === "") {
        formerrors = {
          ...formerrors,
          [data.name]: `${data.label} is required`,
        };
      } else if (formValues[data.name] !== "") {
        let fieldMap = data.name;
        if (data.name === "username") {
          fieldMap = formFieldMap(data);
        }
        formerrors = {
          ...formerrors,
          [data.name]: formErrorHandling(
            formValues[data.name],
            fieldMap,
            formValues,
            data.maxLength,
            data
          ),
        };
      }
    });
    setFormErrors(formerrors);

    // Checking the state errors
    if (new Set(Object.values(formerrors)).size === 1) {
      setLoading(true);

      // Reassigning the state keys with config fieldMap values
      const formField = retailerFormFields.reduce((field, form) => {
        const nameValue = form.name;
        if (
          form.name !== "passwordTwo" &&
          form.name !== "terms" &&
          form.name !== "marketing"
        ) {
          if (form.name === "passwordOne") {
            field["password"] = formValues[nameValue];
          } else {
            field[form.name] = formValues[nameValue];
          }
        }

        if (form.isUsername === true) {
          field["username"] = formValues[nameValue];
        }

        return field;
      }, {});

      const updateUser = await updateUserCall(formField, retailerName);
      setLoading(false);
      if (updateUser.error) {
        const errorValue = updateUser.fields && updateUser.fields[0];
        switch (errorValue) {
          case "email":
            setProfileUpdErrMsg({
              genricErrorMsg:
                "This email address is already linked to another account. Please use a different email address.",
            });
            break;
          case "phone":
            if (Object.keys(formValues).includes("phone")) {
              setProfileUpdErrMsg({
                genricErrorMsg:
                  "This phone number is already linked to another account. Please use a different phone number.",
              });
            } else {
              setFormError(updateUser.error);
            }
            break;
          case "username":
            const username = fields.username;
            if (username === fields.phone) {
              setProfileUpdErrMsg({
                genricErrorMsg:
                  "This phone number is already linked to another account. Please use a different phone number.",
              });
            } else if (username === fields.email) {
              setProfileUpdErrMsg({
                genricErrorMsg:
                  "This email address is already linked to another account. Please use a different email address.",
              });
            } else if (username === fields.loyaltyId) {
              setProfileUpdErrMsg({
                genricErrorMsg:
                  "Your loyalty ID is already linked to another account. Please use a different loyalty ID.",
              });
            } else {
              setProfileUpdErrMsg({ genricErrorMsg: updateUser.error });
            }
            break;
          default:
            setFormErrors({
              ...errors,
              [errorValue]: `Invalid parameter ${errorValue}`,
            });
        }
      } else {
        setSuccessMsg("Your profile has been updated successfully!");
        setProfileUpdErrMsg(null);
        dispatch(updateUserSuccess(retailerName));
      }
    }
  };

  return (
    <Grid
      tabIndex={0}
      container
      className={isMobile ? classes.mobileFormContainer : ""}
    >
      {retailerFormFields.length > 0 &&
        retailerFormFields.map((fields, idx) => (
          <Grid item xs={handleMultiColumn(fields, isMobile)}>
            {/* Textfield start here */}
            {fields.field === "textField" && (
              <FormControl
                className={isMobile ? classes.marginMobile : classes.margin}
                variant="outlined"
              >
                <>
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "auto auto",
                    }}
                  >
                    <label
                      htmlFor={getFieldLabel(fields)}
                      style={{ margin: "0" }}
                    >
                      <FormHelperText
                        className={classes.loyaltyId}
                        id="outlined-weight-helper-text"
                        aria-live="polite"
                      >
                        {getFieldLabel(fields)}{" "}
                        {mandatoryField(fields) && (
                          <span className={classes.mandatoryField}>*</span>
                        )}
                      </FormHelperText>
                    </label>
                    {editField && fields.contactSupport && (
                      <a
                        className={classes.contactBtn}
                        href={`${fields.contactUrl}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Contact Support
                      </a>
                    )}
                  </div>
                  {handleEditablefield(editField, fields.isEditable) && (
                    <label htmlFor={getFieldLabel(fields)}>
                      <FormHelperText
                        className={classes.labelText}
                        id="outlined-weight-helper-text"
                        aria-live="polite"
                      >
                        {formValues[fields.name]}
                      </FormHelperText>
                    </label>
                  )}
                </>
                {editField && fields.isEditable && (
                  <TextField
                    variant="outlined"
                    id={getFieldLabel(fields)}
                    type="string"
                    error={errors[fields.name] !== ""}
                    value={formValues[fields.name]}
                    onChange={handleChange(fields.name, fields)}
                    InputProps={
                      errors[fields.name]
                        ? {
                            endAdornment: (
                              <InputAdornment position="start">
                                {errorIcon}
                              </InputAdornment>
                            ),
                          }
                        : ""
                    }
                    className={handleUsernameClass(
                      fields.name,
                      errors,
                      classes
                    )}
                  />
                )}
              </FormControl>
            )}
            {/* Textfield end  here */}

            {/* SelectBox start  here */}
            {fields.field === "dropdown" &&
              (fields.name === "birthMonth" ||
                fields.name === "birthYear" ||
                fields.name === "birthDay" ||
                fields.name === "state") && (
                <FormControl
                  variant="outlined"
                  className={isMobile ? classes.marginMobile : classes.margin}
                >
                  <>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "auto auto",
                      }}
                    >
                      <label
                        htmlFor={`${idx}-dropdown`}
                        style={{ minHeight: "30px", margin: "0" }}
                      >
                        <FormHelperText
                          className={classes.loyaltyId}
                          id="outlined-weight-helper-text"
                        >
                          {getFieldLabel(fields)}{" "}
                          {mandatoryField(fields) && (
                            <span className={classes.mandatoryField}>*</span>
                          )}
                        </FormHelperText>
                      </label>
                      {editField &&
                        fields.contactSupport &&
                        ((isMobile
                          ? fields.name === "birthMonth"
                          : fields.name === "birthYear") ||
                          fields.name === "state") && (
                          <a
                            className={classes.contactBtn}
                            href={`${fields.contactUrl}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Contact Support
                          </a>
                        )}
                    </div>
                    {handleEditablefield(editField, fields.isEditable) && (
                      <label htmlFor={getFieldLabel(fields)}>
                        <FormHelperText className={classes.labelText}>
                          {fields.name === "birthMonth" &&
                            formValues["birthMonth"] &&
                            `${formValues["birthMonth"]}/${formValues["birthDay"]}/${formValues["birthYear"]}`}
                          {fields.name === "state" && formValues["state"]}
                        </FormHelperText>
                      </label>
                    )}
                  </>
                  {editField && fields.isEditable && (
                    <Select
                      labelId={`${idx}-dropdown`}
                      disabled={fields.disabled}
                      id={`${idx}-dropdown`}
                      value={formValues[fields.name]}
                      error={errors[fields.name] !== ""}
                      inputProps={{
                        "aria-label": "Without label",
                        fontSize: "16px",
                      }}
                      IconComponent={() =>
                        errors[fields.name] ? (
                          <InputAdornment
                            position="end"
                            className={
                              formValues[fields.name]
                                ? classes.selectValueicon
                                : classes.selectIcon
                            }
                          >
                            {errorIcon}
                          </InputAdornment>
                        ) : (
                          <InputAdornment
                            position="end"
                            className={
                              formValues[fields.name]
                                ? classes.selectValueicon
                                : classes.selectIcon
                            }
                          >
                            {dropdownIcon}
                          </InputAdornment>
                        )
                      }
                      className={`${
                        formValues[fields.name]
                          ? classes.selectValueBox
                          : handleSelectClass(fields.name, errors, classes)
                      } 
                      ${classes.unselectedValueBox}`}
                      onChange={handleSelectChange(fields.name)}
                      onKeyDown={handleSelectChange(fields.name)}
                      renderValue={(value) => {
                        if (!value) {
                          return (
                            <span class={classes.selectPlaceholder}>
                              {fields.name}
                            </span>
                          );
                        }
                        return value;
                      }}
                    >
                      {fields.name === "birthMonth" &&
                        birthMonth.map((storeData) => (
                          <MenuItem value={storeData.value}>
                            {storeData.label}
                          </MenuItem>
                        ))}
                      {fields.name === "birthDay" &&
                        birthDay.map((storeData) => (
                          <MenuItem value={storeData.value}>
                            {storeData.label}
                          </MenuItem>
                        ))}
                      {fields.name === "birthYear" &&
                        birthYear.map((storeData) => (
                          <MenuItem value={storeData.value}>
                            {storeData.label}
                          </MenuItem>
                        ))}
                      {fields.name === "state" &&
                        stateData[0].map((storeData) => (
                          <MenuItem value={storeData.value}>
                            {storeData.label}
                          </MenuItem>
                        ))}
                    </Select>
                  )}
                </FormControl>
              )}
            {/* SelectBox end  here */}

            {errors[fields.name] !== "" && (
              <FormHelperText
                tabIndex={0}
                className={
                  isMobile ? classes.errorMobile : classes.errorMessage
                }
                id="outlined-weight-helper-text"
              >
                {errors[fields.name]}
              </FormHelperText>
            )}
          </Grid>
        ))}
      {successMsg && (
        <Grid item xs={12}>
          <FormHelperText
            tabIndex={0}
            className={
              isMobile
                ? classes.alertBoxsucessMobile
                : classes.alertBoxsucesserror
            }
            id="outlined-weight-helper-text"
          >
            {successMsg}
          </FormHelperText>
        </Grid>
      )}
      <Grid item xs={12}>
        {profileUpdErrMsg && (
          <FormHelperText
            tabIndex={0}
            className={
              isMobile ? classes.alertBoxMobile : classes.alertBoxerror
            }
            id="outlined-weight-helper-text"
          >
            {profileUpdErrMsg && profileUpdErrMsg.errorMsg ? (
              <span>{profileUpdErrMsg.errorMsg}</span>
            ) : (
              profileUpdErrMsg.genricErrorMsg
            )}
          </FormHelperText>
        )}
      </Grid>
      {editField && (
        <Grid className={classes.updateProfileBtn} xs={12}>
          <Button
            tabIndex={0}
            variant="contained"
            className={classes.signInbtn}
            onClick={handleCreate}
            disabled={isLoading}
          >
            {!isLoading ? (
              <div data-testid="updateProfileBtn" className="createAccount">
                Save
              </div>
            ) : (
              <div className="loader-button" />
            )}
          </Button>
          <div
            tabIndex={0}
            variant="contained"
            className={classes.cancelBtn}
            onClick={handleCancel}
            disabled={isLoading}
          >
            {!isLoading ? (
              <div className={classes.cancel}>Cancel</div>
            ) : (
              <div className="loader-button" />
            )}
          </div>
        </Grid>
      )}

      <Grid item xs={12}>
        {formError !== "" && formError !== undefined && (
          <FormHelperText
            tabIndex={0}
            className={
              isMobile ? classes.alertBoxMobile : classes.alertBoxerror
            }
            id="outlined-weight-helper-text"
          >
            {formError}
          </FormHelperText>
        )}
      </Grid>
      {openPopUpModal && (
        <PopUpModal
          open={openPopUpModal}
          popUpInfoIcon={errorIcon}
          popUpText={popUpText}
          popUpTitle={"Action Failed"}
          colourButtonName={"Try again"}
          whiteButtonName={"Close"}
          mainColor={
            signupConfig.primary_color || colorTheme?.button_background_color
          }
          closeModal={() => setOpenPopUpModal(false)}
          colourButtonAction={() => setOpenPopUpModal(false)}
          whiteButtonAction={() => setOpenPopUpModal(false)}
        />
      )}
    </Grid>
  );
}

export default ProfileWrapper;
