import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import Submission from "./cashbackSubmissionSearch/Submission";
import GridContainer from "./CashBackOffers";
import { shouldRenderComponent } from '../shared/util/ComponentAuth';
import "./Rebates.scss";

function Rebates(page) {
  let pageData = page.page;

  const useStyles = makeStyles((theme) => ({
    rebatesContainer: {
      paddingBottom: "80px",
      paddingTop: "80px",
      [theme.breakpoints.down(1025)]: {
        paddingBottom: "40px",
        paddingTop: "40px",    
      },
    }
  }));
  const classes = useStyles();


  if (!shouldRenderComponent(pageData.auth_visibility)) {
    return null;
  }

  return (
    <>
    <div className={classes.rebatesContainer}>
      <GridContainer page={pageData} />
      <Submission page={pageData} />
    </div>
    </>
  );
}

export default Rebates;
