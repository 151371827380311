import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import store from '../redux';

import { useDispatch, useSelector } from 'react-redux';
import { SHOW_LOGIN, CLIP_ERROR, CLIP_OFFER } from '../redux/reducers/offer';
import './moreinfo.scss';

const config = store.getState().config;

function OfferItem(props) {
  const siteConfig = props.page
  const dispatch = useDispatch();
  const offerBlock = siteConfig.components.find((component) => component.block_type === 'OfferBlock');
  const metaDataBlock = siteConfig.components.find((component) => component.block_type === 'MetaDataBlock');

  let coupon = props.coupon || '';
  let isFeatured = props.isFeatured;
  let [moreInfo, showInfo] = useState({ show: false });
  const username = useSelector((state) => state.user.username);
  const useStatic = useSelector((state) => state.config.useStatic);
  let linkCondition =
    config.urlParam.ids && config.urlParam.ids.split(',').length === 1;

  let mpid;
  if (offerBlock.use_test_offers) {
    mpid = '';
  } else {
    mpid = offerBlock.mp_id;
  }
  let funnel = metaDataBlock?.funnel;
  let channel = metaDataBlock?.channel;
  

  const linksColor = offerBlock.colorLinks;
  const titleColor = offerBlock.colorTitle;
  const buttonBackground = offerBlock.color_offer_buttons_background;
  const offerBackgroundColor = offerBlock.color_offer_background;
  const colorOfferButtonsText = offerBlock.color_offer_buttons_text;
  const textColor = offerBlock.color_offer_text;
  const contentOrder = offerBlock.content_order;
  const isTwoColumns = props.isMobileTwoColumns;

  function handleViewMore(e) {
    e.preventDefault();
    showInfo({ show: true });
  }

  function handleClose(e) {
    e.preventDefault();
    showInfo({ show: false });
  }

  function handleCardClick(e, mdid) {
    if (!e.target.classList.contains('offer-btn')) {
      dispatch(SHOW_LOGIN({ show: true, mdid: mdid, location: 'grid' }));
    }
  }

  function handleClipCouponClick(e, mdid) {
    e.stopPropagation();
    handleClick(mdid);
  }

  function RenderMoreInfo(props) {
    if (!moreInfo.show) {
      return '';
    }
    return (
      <div className="moreInfo">
        <div
          className="overlay"
        ></div>

        <div className="content">
          <button
            className="close"
            onClick={(e) => {
              handleClose(e);
            }}
          >
            X
          </button>
          <p>{props.description}</p>
        </div>
      </div>
    );
  }
  //display offer card clip button
  function RenderCTA(props) {
    let element = (
      <button
        style={{ backgroundColor: buttonBackground, color: colorOfferButtonsText }}
        onClick={handleClick.bind(this, mdid)}
        className='offer-btn'
        >
        Clip Coupon
      </button>
    );
    if (coupon.clipped) {
      element = (
        <button
          className="offer-btn link"
          disabled
          style={{ backgroundColor: 'gray' }}
        >
          Clipped
        </button>
      );
    }
    return element;
  }
  function RenderFeaturedLogo() {
    if (featuredLogo && isFeatured) {
      return <Card.Img src={featuredLogo} alt="" className="logo-img" />;
    } else {
      return null;
    }
  }
  function OfferImageCheck() {
      return (
        <div
        className={`offer-image-container ${props.isMobileTwoColumns ? 'mobile-two-columns' : ''}`}
      >
        <img
          className={`offer-image ${props.isMobileTwoColumns ? 'mobile-two-columns' : ''}`}
          src={enhancedImageUrl ? enhancedImageUrl : imageUrl}
          alt={description}
        />
      </div>
      );
  }
  // Tags is required for clip coupon??
  function handleClick(mdid) {
    const options = {
      endpoint: 'offers/load',
      method: 'POST',
      body: { 
        username: username, 
        id: mdid,
        mediaPropertyId: mpid,
        tags: offerBlock.tag,
        channel: channel,
        funnel: funnel,
        captchaToken: '22',
        location: 'grid' 
      },
      retailer: props.retailer.dpn_instance
    };

    if (useStatic) {
      options.endpoint = 'json/clip.json';
    }
    if (!username) {
      dispatch(SHOW_LOGIN({ show: true, mdid: mdid, location: 'grid' }));
    } else {
      handleClip(options, mdid);
    }
  }

  function handleClip(options, mdid) {
    dispatch(CLIP_OFFER(options)).then((resp) => {
      //TODO: errors not firing
      if (!resp.payload.success) {
        dispatch(CLIP_ERROR(resp));
      }
    });
  }

  const { imageUrl, enhancedImageUrl, brand, description, mdid, clipEndDateTime } = coupon;

  const expiration = new Date(clipEndDateTime.iso);
  const month = expiration.getMonth() + 1;
  const day = expiration.getDate();
  let year = expiration.getFullYear().toString().substr(2, 4);
  const featuredLogo = config.urlParam.LOGO || config.urlParam.logo;

  return (
    <Card
      className={`offer2 ${props.isMobileTwoColumns ? 'mobile-two-columns' : ''}`}
      style={{ backgroundColor: offerBackgroundColor, cursor: 'pointer' }}
      onClick={(e) => handleCardClick(e, mdid)}
    >      <div>
        {contentOrder === 'order1' && (
            <OfferImageCheck />
        )}
        {contentOrder !== 'order2' && contentOrder !== 'order1' && (
            <OfferImageCheck />
        )}
        <div>
          <div className="content" style={{ color: textColor }}>
            <RenderFeaturedLogo />
            <div className='availability-text'></div>
            <div className='brand-text' style={{ color: titleColor }}>{brand}</div>
            <div className="offer-description">{description}</div>
            <Card.Text className="expiration-text">
              Expires: {month}/{day}/{year}
            </Card.Text>
          </div>
          {contentOrder === 'order2' && (
              <OfferImageCheck />
          )}
          <RenderCTA mdid={mdid} />
          <RenderMoreInfo description={description} />
        </div>
      </div>
    </Card>
  );
  
}

export default OfferItem;
