import FingerprintJS from '@fingerprintjs/fingerprintjs-pro'

export const getFingerprintData = async (publicKey) => {
    const fpPromise = FingerprintJS.load({
      apiKey: publicKey,
      region: 'us',
    });
   
    const fp = await fpPromise;
    const fingerprintData = await fp.get({ extendedResult: true });
   
    return {
      fpId: fingerprintData.visitorId,
      fpIpAddress: fingerprintData.ip,
      fpAddInfo: [
        {
          key: "FingerPrintRequestId",
          value: fingerprintData.requestId
        }
      ]
    };
   };