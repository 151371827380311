import React, { useState, useEffect } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  useParams,
  Outlet,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { useDispatch } from "react-redux";
import accountActions from "./redux/actions/accountActions";
import SitePage from "./site";
import CPGPage from "./pages/cpg";
import HomePage from "./pages/homePage";
import EarnPoints from "./pages/earnPointsPage";
import AccountPage from "./pages/accountPage";
import RedeemPoints from "./pages/redeemPointsPage";
import { SiteConfigProvider } from "./SiteConfigContext";
import axios from "axios";
import RecipePage from "./recipes/recipe";
import BrandsiteList from "./brandsiteList";
import LoginWrapper from "./pages/login";
import SignupWrapper from "./pages/Signup";
import ForgotWrapper from "./pages/forgotPassword";
import NotFoundPage from "./404"; 
import ResetPassword from "./pages/resetPassword";
import site_config_json from "./site-config.json";
import MetaDataBlock from "./metadata";
import retailer from "./config/retailer";
import "./App.css";
import { getFullPath } from "./config/retailerConfig";

function SiteComponent() {
  const { siteName } = useParams();
  const location = useLocation();
  let apiUrl;
  
  // determines if the frontend will call localhost or the cloud application for its backend response
  apiUrl = process.env.REACT_APP_BACKEND;

  const brandsiteUrl = `${apiUrl}/brandsites/${siteName}`;
  const dispatch = useDispatch();
  const [siteConfig, setSiteConfig] = useState(null);
  const [ageGate, setAgeGate] = useState(false)
  const [retailerConfig, setRetailerConfig] = useState(null);
  const routesList = ['products', 'faq', 'contact', 'merch', 'terms'];
  const navigate = useNavigate();
  
  const link =
    document.querySelector("link[rel*='icon']") ||
    document.createElement("link");
  link.type = "image/x-icon";
  link.rel = "shortcut icon";
  const metaBlock =
    siteConfig &&
    siteConfig[0].components.find(
      (component) => component.block_type === "MetaDataBlock"
    );
  link.href =
    metaBlock && metaBlock.favicon
      ? metaBlock?.favicon
      : "";
  document.getElementsByTagName("head")[0].appendChild(link);

  useEffect(() => {
    // getting siteConfig depending on whether or not the app is in preview_mode
    const fetchSiteConfig = async () => {
      try {
        let data;
        if (process.env.REACT_APP_PREVIEW_MODE === "true") {
          const response = await axios.get(brandsiteUrl);
          data = response.data;
          setSiteConfig(data);
          dispatch(accountActions.serRetailerConfig(data[0]));
          setRetailerConfig(data[0]);          
        } else {
          // if the site is not in preview mode, it will use the JSON that was created from the backend call in the build process
          // this is what sites use in production so it is not dependent on the API
          data = site_config_json;
          setSiteConfig(data);
          dispatch(accountActions.serRetailerConfig(data[0]));
          setRetailerConfig(data[0]);
        }
      } catch (error) {
        console.error("Error fetching site_config:", error);
      }
    };

    fetchSiteConfig();
  }, [brandsiteUrl, dispatch]);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      dispatch(accountActions.setUserLoginStatus());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);


  if (!siteConfig || !retailerConfig) {
    return <div>Loading...</div>;
  }
  // implementing logic for A/B testing if there is a value for brandsite_b listed on the first page
  if (siteConfig[0].brandsite_b) {
    console.log("A/B testing enabled");

    if (!localStorage.getItem("AB_TEST_VARIABLE")) {
      let brandsite_a_probability = siteConfig[0].brandsite_a_probability;
      if (brandsite_a_probability === null) {
        brandsite_a_probability = 0.5;
      }
      const randomValue = Math.random() < brandsite_a_probability ? "A" : "B";
      localStorage.setItem("AB_TEST_VARIABLE", randomValue);
    } else {
      console.log(
        "AB TEST VARIABLE:",
        localStorage.getItem("AB_TEST_VARIABLE")
      );
    }

    if (localStorage.getItem("AB_TEST_VARIABLE") === "B") {
      console.log("setting brandsite to version B");
      setSiteConfig(siteConfig[0].brandsite_b);
    }
  }


  const RouteWrapper = ({ path, authCheckEnabled = false }) => {
    const pageConfig = siteConfig.find((page) => page.slug === path);
    if (!pageConfig) {
      return <Outlet />;
    }
    //  handle navigate
    const ageGateBlock = siteConfig && siteConfig[0]?.components.find(component => component.block_type === 'AgeGateBlock')
    if(ageGateBlock && window.sessionStorage.getItem('ageGate_Value')==='false'){
      navigate(getFullPath("home"))
    }

    const redirectPage = siteConfig.find(
      (page) => page.id === pageConfig?.unauthenticated_redirect_page
    );
    const defaultRoute = redirectPage ? redirectPage.slug : "home";
  
    const isOutOfRange = pageConfig.end_date ? isOutOfDateRange(pageConfig) : false;
  
    if (isOutOfRange && pageConfig.out_of_range_redirect) {
      console.log(getFullPath(pageConfig.out_of_range_redirect))
      return <Navigate to={getFullPath(pageConfig.out_of_range_redirect)} />;
    }
  
    if (authCheckEnabled) {
      return localStorage.getItem("token") &&
        pageConfig.auth_visibility === "authenticated" ? (
        <Outlet />
      ) : (
        <Navigate to={defaultRoute} />
      );
    }
  
    return <Outlet />;
  };
  
  const isOutOfDateRange = (page) => {
    if (!page || !page.live_date || !page.end_date) {
      return false;
    }
  
    const currentDate = new Date();
    const liveDate = new Date(page.live_date);
    const endDate = new Date(page.end_date);
  
    return currentDate < liveDate || currentDate > endDate;
  };

  return (
    <SiteConfigProvider config={siteConfig}>
      {(retailerConfig.site_type === "MyLoyalty" || retailerConfig.site_type === "LoyaltySync") && 
      metaBlock && <MetaDataBlock page={siteConfig[0]} />}
      <Routes>
        {/* looks at siteConfig from the backend or JSON and makes a page
         with the given slug from every list entry */}

        {/* TODO: This routing will enhance later */}
        {retailerConfig.site_type !== "MyLoyalty" && retailerConfig.site_type !== "LoyaltySync" ? (
          <>
            {siteConfig.map((item) => (
              <React.Fragment key={item.id}>
                <Route path={item.slug} element={<SitePage page={item} />} />
                <Route
                  path={`${item.slug}recipes/:slug`}
                  element={<RecipePage page={item} siteName={siteName} isBrandsite={true} />}
                />
              </React.Fragment>
            ))}
          </>
        ) : (
          <>
            <React.Fragment>
              <Route element={<RouteWrapper path={retailerConfig.slug} />}>
                <Route
                  path={retailerConfig.slug}
                  element={<CPGPage page={siteConfig} />}
                />
              </Route>

              <Route element={<RouteWrapper path={`${retailerConfig.slug}login`} />}>
                <Route
                  path={`${retailerConfig.slug}login`}
                  element={<LoginWrapper page={retailerConfig} />}
                />
              </Route>

              <Route element={<RouteWrapper path={`${retailerConfig.slug}signup`} />}>
                <Route
                  path={`${retailerConfig.slug}signup`}
                  element={<SignupWrapper page={retailerConfig} />}
                />
              </Route>

              <Route element={<RouteWrapper path={`${retailerConfig.slug}forgot`} />}>
                <Route
                  path={`${retailerConfig.slug}forgot`}
                  element={<ForgotWrapper page={retailerConfig} />}
                />
              </Route>

              <Route element={<RouteWrapper path={`${retailerConfig.slug}resetpassword`} />}>
                <Route
                  path={`${retailerConfig.slug}resetpassword/:token`}
                  element={<ResetPassword retailerConfig={retailerConfig} />}
                />
              </Route>

              <Route element={<RouteWrapper path={`${retailerConfig.slug}home`}  />}>
                <Route
                  path={`${retailerConfig.slug}home`}
                  element={<HomePage page={siteConfig} slug="home" />}
                />
              </Route>

              <Route element={<RouteWrapper path={`${retailerConfig.slug}earn_points`} />}>
                <Route
                  path={`${retailerConfig.slug}earn_points`}
                  element={<EarnPoints page={siteConfig} />}
                />
              </Route>  

              {/* Authenticated routes */}
              <Route element={<RouteWrapper path={`redeem`} authCheckEnabled={true} />}>
                <Route
                  path={`${retailerConfig.slug}redeem`}
                  element={<RedeemPoints page={siteConfig} />}
                />
              </Route>

              <Route element={<RouteWrapper path={`account`} authCheckEnabled={true} />}>
                <Route
                  path={`${retailerConfig.slug}account`}
                  element={<AccountPage page={siteConfig} />}
                />
              </Route>

              {routesList.map((route) => (
                <Route element={<RouteWrapper path={route} authCheckEnabled={false} />}>
                <Route
                  path={`${retailerConfig.slug}${route}`}
                  element={
                    <HomePage page={siteConfig} slug={route} />
                  }
                />
              </Route>
              ))}

              <Route
                path={`${retailerConfig.slug}:pageSlug/recipes/:slug`}
                element={<RecipePage page={siteConfig} siteName={siteName} isBrandsite={false} />}
              />
            </React.Fragment>
          </>
        )}
      </Routes>
    </SiteConfigProvider>
  );
}

function App() {
  let apiUrl;
  apiUrl = process.env.REACT_APP_BACKEND;

  if (process.env.REACT_APP_PREVIEW_MODE === "true") {
    // every site can be accessed from its siteName as a route path in preview mode.
    // This can be viewed from a list in CloudFront
    return (
      <div>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<BrandsiteList apiUrl={apiUrl} />} />
            <Route path="/:siteName/*" element={<SiteComponent />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </BrowserRouter>
      </div>
    );
  } else {
    // out of preview mode, only one brandsite can be viewed at a time and all of its routes begin with / instead of /:siteName
    return (
      <div>
        <BrowserRouter>
          <Routes>
            <Route path="/*" element={<SiteComponent />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
