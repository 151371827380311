import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import store from '../redux';

import { useDispatch, useSelector } from 'react-redux';
import { SHOW_LOGIN, CLIP_ERROR, CLIP_OFFER } from '../redux/reducers/offer';
import './moreinfo.scss';

const config = store.getState().config;

function OfferItem(props) {
  const siteConfig = props.page
  const block = siteConfig.components.find(component => component.block_type === 'OfferBlock');
  const dispatch = useDispatch();

  let coupon = props.coupon || '';
  let isFeatured = props.isFeatured;
  let [moreInfo, showInfo] = useState({ show: false });
  const username = useSelector((state) => state.user.username);
  const useStatic = useSelector((state) => state.config.useStatic);
  let linkCondition =
    config.urlParam.ids && config.urlParam.ids.split(',').length === 1;
  // let mpidbyEnv = store.getState().config.myEnv;
  // const mpid = store.getState().config.retailer.mpid[mpidbyEnv];
  const mpid = siteConfig.mpId;
  let funnel = siteConfig.funnel ? siteConfig.funnel : 'n/a';
  let channel = siteConfig.channel ? siteConfig.channel : 'n/a';

  // const primaryBackgroundColor = block.colorPrimary;
  // const secondaryBackgroundColor = block.colorSecondary;
  const linksColor = block.colorLinks;
  const titleColor = block.colorTitle;
  const buttonBackground = block.color_offer_buttons_background;
  const offerBackgroundColor = block.color_offer_background;
  const colorOfferButtonsText = block.color_offer_buttons_text;
  const textColor = block.color_offer_text;
  const contentOrder = block.content_order;
  // const offerButtonsBackgroundColor = block.colorOfferButtonsBackground;

  function handleViewMore(e) {
    showInfo({ show: true });
    // e.preventDefault();
  }

  function handleClose(e) {
    e.preventDefault();
    showInfo({ show: false });
  }
  function RenderMoreInfo(props) {
    if (!moreInfo.show) {
      return '';
    }
    return (
      <div className="moreInfo">
        <div
          className="overlay"
          // style={{ backgroundColor: primary, opacity: '.99' }}
        ></div>

        <div className="content">
          <button
            className="close"
            onClick={(e) => {
              handleClose(e);
            }}
          >
            X
          </button>
          <p>{props.description}</p>
        </div>
      </div>
    );
  }
  //display offer card clip button
  function RenderCTA(props) {
    let element = (
      <button
        style={{ backgroundColor: buttonBackground, color: colorOfferButtonsText }}
        onClick={handleClick.bind(this, mdid)}
        className="offer-btn"
      >
        Clip
      </button>
    );
    if (coupon.clipped) {
      element = (
        <button
          className="offer-btn link"
          disabled
          style={{ backgroundColor: buttonBackground }}
        >
          Clipped
        </button>
      );
    }
    return element;
  }
  function RenderFeaturedLogo() {
    if (featuredLogo && isFeatured) {
      return <Card.Img src={featuredLogo} alt="" className="logo-img" />;
    } else {
      return null;
    }
  }
  //displays offer card image
  function OfferImageCheck() {
    if (isFeatured && enhancedImageUrl) {
      return (
        <Card.Img
          src={enhancedImageUrl ? enhancedImageUrl : imageUrl}
          alt={description}
          className="offer-img"
        />
      );
    } else {
      return (
        <Card.Img src={imageUrl} alt={description} className="offer-img" />
      );
    }
  }
  function handleClick(mdid) {
    const options = {
      endpoint: 'offers/load',
      method: 'POST',
      body: { 
        username: username, 
        id: mdid,
        mediaPropertyId: mpid,
        channel: channel,
        funnel: funnel,
        captchaToken: '22',
        location: 'grid' 
      },
      retailer: props.retailer.dpn_instance
    };

    if (useStatic) {
      options.endpoint = 'json/clip.json';
    }
    if (!username) {
      dispatch(SHOW_LOGIN({ show: true, mdid: mdid, location: 'grid' }));
    } else {
      handleClip(options, mdid);
    }
  }

  function handleClip(options, mdid) {
    dispatch(CLIP_OFFER(options)).then((resp) => {
      //TODO: errors not firing
      if (!resp.payload.success) {
        dispatch(CLIP_ERROR(resp));
      }
    });
  }

  const { imageUrl, enhancedImageUrl, brand, description, mdid, clipEndDateTime } = coupon;

  const expiration = new Date(clipEndDateTime.iso);
  const month = expiration.getMonth() + 1;
  const day = expiration.getDate();
  let year = expiration.getFullYear().toString().substr(2, 4);
  const featuredLogo = config.urlParam.LOGO || config.urlParam.logo;

  return (
    <Card className="offer" style={{ backgroundColor: offerBackgroundColor }}>
      <div className="featured-block">
        {contentOrder === 'order1' && (
          <a href={`?ids=${mdid}`} className={linkCondition ? 'disable-link' : ''}>
            <OfferImageCheck />
          </a>
        )}
        {contentOrder !== 'order2' && contentOrder !== 'order1' && (
          <a href={`?ids=${mdid}`} className={linkCondition ? 'disable-link' : ''}>
            <OfferImageCheck />
          </a>
        )}
        <div>
          <Card.Body className="content" style={{ color: textColor }}>
            <RenderFeaturedLogo />
            <Card.Title style={{ color: titleColor }}>{brand}</Card.Title>
            <Card.Text className="description">{description}</Card.Text>
            <Card.Link
              className="moreLink"
              style={{ color: linksColor }}
              onClick={(e) => {
                handleViewMore(e);
              }}
              target="_blank"
              href={props.retailer.digital_coupons_link}
            >
              View More
            </Card.Link>
            <Card.Text className="expiration">
              Exp. {month}/{day}/{year}
            </Card.Text>
          </Card.Body>
          {contentOrder === 'order2' && (
            <a href={`?ids=${mdid}`} className={linkCondition ? 'disable-link' : ''}>
              <OfferImageCheck />
            </a>
          )}
          <RenderCTA mdid={mdid} />
          <RenderMoreInfo description={description} />
        </div>
      </div>
    </Card>
  );
  
}

export default OfferItem;
