import React, { useState } from 'react';
import './ageGate.scss';
function CheckboxAgeGate({ page, title, instructions, updateAgeGate }) {
  const [reject, setReject] = useState(false);
  const block = page.components.find(component => component.block_type === 'AgeGateBlock');
  const handleAccept = () => {
    window.sessionStorage.setItem('ageGate_Value','false')
    setReject(true);
  };
  const handleVerifyClick = () => {
    if (!reject) {
      window.sessionStorage.setItem('ageGate_Value','true')
      window.open(block.yes_button_link || "home", "_self")
      updateAgeGate(false)
    }
  };
  return (
    <>
      <div className="age-gate-container" style={{ backgroundImage: "url(" + block.background_image + ")", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center", backgroundColor: block.background_color }}>
        <div className="age-gate">
          <img src={block.brand_logo}></img>
          <h2 style={{ color: block.text_color }}>{reject ? block.title_No : title || block.title}</h2>
          <label style={{ color: block.text_color }}>
            {reject ? block.instructions_No : instructions || block.instructions}
          </label>
          {!reject && (<div className='button-container'>
            <button className='btn' onClick={handleVerifyClick} style={{ backgroundColor: block.button_color, borderColor: block.border_color }}>
              {block.submit_button_text}
            </button>
            <button className='btn' onClick={handleAccept} style={{ backgroundColor: block.button_color, borderColor: block.border_color }}>
              {block.cancel_button_text}
            </button>
          </div>)}
        </div>
        <div className='links' style={{color:block.text_color}}>
          <p><a href={block.terms_link} target="_blank" style={{color:block.text_color}} >Terms and Conditions</a><a href={block.privacy_link} target="_blank" style={{color:block.text_color, marginLeft:'24px'}} > Privacy Policy </a></p>
        </div>
      </div>
    </>
  );
}
export default CheckboxAgeGate;