import React, { useState } from 'react';
import './Rebates.scss';
import { Modal, Button } from 'react-bootstrap';

function SuccessPopUp({ setShowSuccessPopUp, firstName, lastName, email, paymentMethod, paymentEmail, submissionNumber, venmoNumber, handleSuccess, clearForm }) {
  const handleClose = () => {
    setShowSuccessPopUp(false);
    handleSuccess();
    clearForm();
  };
  return (
    <Modal show={true} onHide={handleClose}>
  <Modal.Header style={{ backgroundColor: 'transparent', border: 'none' }}>
    <div
      type="button"
      id='popup-close-button'
      onClick={handleClose}
      aria-label="Close"
      style={{ cursor: 'pointer', float: 'right', marginLeft: 'auto' }}
    >
      <span aria-hidden="true" style={{ color: "black" }}>&times;</span>
    </div>
  </Modal.Header>
  <Modal.Body style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <img src='images/check-circle.png' style={{ margin: '0 auto', paddingBottom: '25px' }} alt="Check Icon" />
    <div><h1 className='rebates-success-popup-header'>WE GOT YOUR SUBMISSION!</h1></div>
    <div className='rebates-success-popup-text'>Your Submission Tracking Number: <strong>{submissionNumber}</strong></div>
    <div style={{ backgroundColor: 'lightgrey', height: '1px', width: '90%' }}></div>
    <div className='success-details'>
        <div className='your-details'>
            <h5>YOUR DETAILS</h5>
            <div className='row'>
                <div className='col'><strong>FIRST NAME</strong></div>
                <div className='col'>{firstName}</div>
            </div>
            <div className='row'>
                <div className='col'><strong>LAST NAME</strong></div>
                <div className='col'>{lastName}</div>
            </div>
            <div className='row'>
                <div className='col'><strong>EMAIL</strong></div>
                <div className='col'>{email}</div>
            </div>
        </div>
        <div className='payment-details'>
          <h5>PAYMENT DETAILS</h5>
          <div className='row'>
              <div className='col'><strong>PAYMENT METHOD</strong></div>
              <div className='col'>{paymentMethod}</div>
          </div>
          {paymentMethod === 'Venmo' ? (
              <div className='row'>
                  <div className='col'><strong>PAYMENT PHONE</strong></div>
                  <div className='col'>{venmoNumber}</div>
              </div>
          ) : (
              <div className='row'>
                  <div className='col'><strong>PAYMENT EMAIL</strong></div>
                  <div className='col'>{paymentEmail}</div>
              </div>
          )}
      </div>
    </div>
  </Modal.Body>
</Modal>

  );
}

export default SuccessPopUp;
