import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Collapse, useMediaQuery, Button } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import MantineDropZone from './mantineDropZone'
import PreviewSubmission from './previewSubmission';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import './Rebates.scss';
import { getOfferSummaryByOfferCode } from '../service/submission_service';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 3, 3),
    width: '100%',
    overflow: 'hidden',
    borderRadius: '24px',
    maxWidth: '380px',
    position: 'relative',
    overflowY: 'scroll',
    paddingBottom: theme.spacing(2),
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
    maxHeight: 'calc(100vh - 64px)',
    [theme.breakpoints.up('lg')]: {
      maxWidth: '1200px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  contentContainer: {
    [theme.breakpoints.up('lg')]: {
      width: '476px',
      padding: '64px 20px 64px 24px',
    }
  },
  leftContainer: {
    [theme.breakpoints.up('lg')]: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      width: '100%',
      height: 'auto',
      gap: '24px',
    },
  },
  closeButton: {
    display: 'flex',
    width: '24px',
    height: '24px',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'absolute',
    right: '24px',
    top: '24px',
    cursor: 'pointer',
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '12px',
    [theme.breakpoints.up('lg')]: {
      marginRight: '24px',
      paddingBottom: 0,
    },
  },
  image: {
    height: '148px',
    width: '148px',
    [theme.breakpoints.up('lg')]: {
      height: '186px',
      width: '186px'
    },
  },
  title: {
    color: '#000',
    fontFamily: 'Roboto, Arial, sans-serif',
    fontWeight: 700,
    lineHeight: '28.8px',
    fontSize: '24px',
  },
  offerDescription: {
    color: '#25272C',
    fontFamily: 'Roboto, Arial, sans-serif',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '160%',
    [theme.breakpoints.up('lg')]: {
      fontSize: '18px'
    }
  },
  description: {
    overflow: 'hidden',
    color: '#25272C',
    fontFamily: 'Roboto, Arial, sans-serif',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '160%',
  },
  expiration: {
    color: '#6B7280',
    fontFamily: 'Roboto, Arial, sans-serif',
    fontSize: '13.5px',
    lineHeight: '150%',
    [theme.breakpoints.up('sm')]: {
      lineHeight: '160%',
    },
  },
  termsContainer: {
    width: '100%',
    padding: theme.spacing(2),
    background: '#E6F1FA',
    borderRadius: '12px',
    border: '1px #0071CE solid',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: theme.spacing(2),
    display: 'inline-flex',
    marginTop: '16px',
    [theme.breakpoints.up('lg')]: {
      border: 'none',
      background: 'white',
      gap: '0',
      padding: '0',
    }
  },
  termsInnerContainer: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(2),
    display: 'column',
    cursor: 'pointer',
  },
  termsText: {
    display: 'flex',
    flex: '1 1 0',
    alignItems: 'center',
    color: '#0071CE',
    fontSize: '16px',
    fontFamily: 'Roboto, Arial, sans-serif',
    fontWeight: '700',
    wordWrap: 'break-word',
  },
  arrowIcon: {
    color: '#0071CE',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  arrowIconExpanded: {
    transform: 'rotate(180deg)',
  },
  termsContent: {
    width: '100%',
    color: '#222222',
    fontSize: '13px',
    fontFamily: 'Roboto, Arial, sans-serif',
    fontWeight: '400',
    wordWrap: 'break-word',
    marginTop: '16px',
    [theme.breakpoints.up('lg')]: {
      color: '#6D7B88',
    }
  },
  formInput: {
    display: 'flex',
    padding: '16px',
    alignItems: 'center',
    gap: '10px',
    alignSelf: 'stretch',
    borderRadius: '12px',
    border: '1px solid #D1D9E2',
    background: '#FFF',
  },
  formLabel: {
    marginBottom: '8px',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '24px',
    width: '326px',
    [theme.breakpoints.up('lg')]: {
      width: '538px',
    }
  },
  submissionInformation: {
    color: '#002F57',
    fontFamily: 'Oswald',
    fontSize: '31px',
    fontWeight: '500',
    lineHeight: '110%',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('lg')]: {
      textAlign: 'left',
      fontSize: '49px',
    }
  },
  mailIn: {
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: '20px',
    lineHeight: '120%',
    paddingTop: '24px',
    [theme.breakpoints.up('lg')]: {
      textAlign: 'left',
      paddingTop: '8px',
    }
  },
  mailInInstructions: {
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: '16px',
    color: '#6D7B88',
    paddingTop: '8px',
    [theme.breakpoints.up('lg')]: {
      textAlign: 'left'
    }
  },
  rightContainer: {
    [theme.breakpoints.up('lg')]: {
      flex: 1,
      padding: theme.spacing(2),
    },
  },

}));

const SubmissionForm = ({ open, offer, onClose, page, onSuccess, setShowFailedPopUp }) => {
  const classes = useStyles();
  const { register, handleSubmit, formState: { errors }, reset, getValues } = useForm();
  
  const [selectedButton, setSelectedButton] = useState(null);
  const [showForm, setShowForm] = useState(true);
  const [showPreview, setShowPreview] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [paymentMethodError, setPaymentMethodError] = useState('');
  const [termsExpanded, setTermsExpanded] = useState(false);
  const [showSuccessPopUp, setShowSuccessPopUp] = useState(false);
  const [offerSummary, setOfferSummary] = useState(null);

  const isDesktop = useMediaQuery('(min-width:1200px)');
  const block = page.components.find(component => component.block_type === 'Rebates');

  useEffect(() => {
    if (!open) {
      reset();
      setSelectedButton(null);
      setUploadedFiles([]);
      setFormSubmitted(false);
      setPaymentMethodError('');
      setTermsExpanded(false);
      setShowFailedPopUp(false);
      setShowSuccessPopUp(false);
    }
  }, [open, reset]);

  useEffect(() => {
    const fetchOfferSummary = async () => {
      if (!offer.terms) {
        try {
          const summary = await getOfferSummaryByOfferCode(block.client_number, offer.offerCode);
          setOfferSummary(summary);
        } catch (error) {
          console.error('Error fetching offer summary:', error);
        }
      } else {
        setOfferSummary(offer);
      }
    };
  
    if (open && offer.offerCode) {
      fetchOfferSummary();
    }
  }, [open, block.client_number, offer.offerCode, offer.terms]);

  const toggleTerms = () => {
    setTermsExpanded(!termsExpanded);
  };

  const clearForm = () => {
    reset();
    setSelectedButton(null);
    setUploadedFiles([]);
  };

  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
    setPaymentMethodError('');
  };

  const handleEdit = () => {
    setShowForm(true);
    setShowPreview(false);
  };

  const handleCancel = () => {
    setShowPreview(false);
    setShowForm(true);
  };

  const handleSuccess = () => {
    setShowPreview(false);
  };

  const closeForm = () => {
    onClose();
    clearForm();
  };

  const closePreview = () => {
    setShowPreview(false);
    document.body.style.overflow = 'auto';
  };

  const handleFormSubmit = (data) => {
    setFormSubmitted(true);
    setPaymentMethodError('');
    if (uploadedFiles.length === 0) {
      return;
    }
    if (selectedButton === null) {
      setPaymentMethodError('Please select a payment method');
      return;
    }
    setShowForm(false);
    setShowPreview(true);
  };

  const handleFileUpload = (files) => {
    setUploadedFiles(files);
  };

  const closeFailedPopup = () => {
    setShowFailedPopUp(false);
  };

  const closeSuccessPopup = () => {
    setShowSuccessPopUp(false);
  };

  const expirationDate = new Date(offer.offerEndDate)
  
  const formattedDate = expirationDate 
    ? `${expirationDate.getMonth() + 1}/${expirationDate.getDate()}/${expirationDate.getFullYear()}`
    : 'Loading...';
  const imageSrc = offer.imageLink || offer.thumbnailImage;
  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={closeForm}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box className={classes.paper}>
          <IconButton className={classes.closeButton} onClick={closeForm}>
            <CloseIcon />
          </IconButton>
          <Box className={classes.contentContainer}>
            <Box className={classes.leftContainer}>
              <Box className={classes.imageContainer}>
                <img className={classes.image} src={imageSrc} alt="Product" />
              </Box>
              <Box className={classes.offerDetailsContainer}>
                {/* <Typography className={classes.title}>{offer.offerTitle || 'Default Title'}</Typography> */}
                <Typography className={classes.title}>
                  {offer.offerTitle || offer.title || ''}
                </Typography>
                <Typography className={classes.offerDescription}>
                  {offer.offerDescription || offer.description || ''}
                </Typography>
                <Typography className={classes.expiration}>Expires: {formattedDate}</Typography>
              </Box>
            </Box>
            <Box className={classes.termsContainer}>
              {isDesktop ? (
                <Typography 
                  className={classes.termsContent}
                  dangerouslySetInnerHTML={{ __html: offerSummary ? offerSummary.terms : 'Loading terms...' }}
                />
              ) : (
                <Box className={classes.termsInnerContainer} onClick={toggleTerms}>
                  <Box style={{ display: 'flex', justifyContent: 'flex-end', width: 'auto' }}>
                    <Typography className={classes.termsText}>Read Terms & Conditions</Typography>
                    <ExpandMoreIcon className={`${classes.arrowIcon} ${termsExpanded ? classes.arrowIconExpanded : ''}`} />
                  </Box>
                  <Collapse in={termsExpanded} unmountOnExit>
                  <Typography 
                    className={classes.termsContent}
                    dangerouslySetInnerHTML={{ __html: offerSummary ? offerSummary.terms : 'Loading terms...' }}
                  />
                  </Collapse>
                </Box>
              )}
            </Box>
          </Box>
          <Box className={classes.rightContainer}>
            {showForm && (
              <Box className={classes.formContainer}>
                <Typography className={classes.submissionInformation}>SUBMISSION INFORMATION</Typography>
                <div className='tw-mt-12 lg:tw-mt-2'>
                  <form onSubmit={handleSubmit(handleFormSubmit)} style={{ backgroundColor: offer.colorBackground2 }}>
                    <div>
                      <div>
                        <label htmlFor="firstName" className={`form-label rebates-form-label ${classes.formLabel}`}>First Name <span className='red'>*</span></label>
                        <input
                          type="text"
                          className={`form-control ${errors.firstName ? 'is-invalid' : ''} ${classes.formInput}`}
                          id="firstName"
                          placeholder="Enter your first name"
                          {...register("firstName", {
                            required: "This field is required",
                            maxLength: {
                              value: 16,
                              message: "First name must be less than 16 characters"
                            },
                            pattern: {
                              value: /^[A-Za-z'-]+$/,
                              message: "First name must only contain letters, dashes (-), and apostrophes ('), no spaces or other special characters allowed"
                            }
                          })}
                        />
                        {errors.firstName && <span className="invalid-feedback">{errors.firstName.message}</span>}
                      </div>
                      <div className="mb-3 label-left with-spacing">
                        <label htmlFor="lastName" className={`form-label rebates-form-label ${classes.formLabel}`}>Last Name <span className='red'>*</span></label>
                        <input
                          type="text"
                          className={`form-control ${errors.lastName ? 'is-invalid' : ''} ${classes.formInput}`}
                          id="lastName"
                          placeholder="Enter your last name"
                          {...register("lastName", {
                            required: "This field is required",
                            maxLength: {
                              value: 16,
                              message: "Last name must be less than 16 characters"
                            },
                            pattern: {
                              value: /^[A-Za-z'-]+$/,
                              message: "Last name must only contain letters, dashes (-), and apostrophes ('), no spaces or other special characters allowed"
                            }
                          })}
                        />
                        {errors.lastName && <span className="invalid-feedback">{errors.lastName.message}</span>}
                      </div>
                      <div className="mb-3 label-left">
                        <label htmlFor="email" className={`form-label ${classes.formLabel}`}>Email <span className='red'>*</span></label>
                        <input
                          type="email"
                          className={`form-control ${errors.email ? 'is-invalid' : ''} ${classes.formInput}`}
                          id="email"
                          placeholder="Enter your email address"
                          {...register('email', { required: true })}
                        />
                        {errors.email && <span className="invalid-feedback">Email is required</span>}
                      </div>
                      <div className='with-spacing'>
                        <h3 className='product-upc'>PAYMENT METHOD</h3>
                        <p className='product-upc-desc'>How would you like to receive your rebate?</p>
                        <div className="container">
                          <div className="row" style={{gap: '8px'}}>
                            <div className="col" style={{ padding: '0', margin: '0' }}>
                              <button
                                type="button"
                                className={`btn btn-primary rebates-custom-button ${selectedButton === 'PayPal' ? 'selected-button' : ''}`}
                                onClick={() => handleButtonClick('PayPal')}
                              >
                                PayPal
                              </button>
                            </div>
                            <div className="col" style={{ padding: '0', margin: '0' }}>
                              <button
                                type="button"
                                className={`btn btn-primary rebates-custom-button ${selectedButton === 'Venmo' ? 'selected-button' : ''}`}
                                onClick={() => handleButtonClick('Venmo')}
                              >
                                Venmo
                              </button>
                            </div>
                          </div>
                        </div>
                        {paymentMethodError && (
                          <div style={{ marginTop: '5px' }}>
                            <span style={{ color: 'red', fontWeight: '700' }}>{paymentMethodError}</span>
                          </div>
                        )}
                      </div>
                      {selectedButton === "PayPal" && (
                        <div className="mb-3 label-left" style={{ marginTop: '10px' }}>
                          <label htmlFor="paypalemail" className={`form-label ${classes.formLabel}`} style={{ padding: '5px' }}>
                            <strong>PayPal Email <span className='red'>*</span></strong>
                          </label>
                          <input
                            type="email"
                            className={`form-control ${errors.paypalemail ? 'is-invalid' : ''} ${classes.formInput}`}
                            id="paypalemail"
                            placeholder="Enter your PayPal account email address"
                            {...register('paypalemail', { required: selectedButton === 'PayPal' })}
                          />
                          {errors.paypalemail && <span className="invalid-feedback">PayPal email is required</span>}
                        </div>
                      )}
                      {selectedButton === "Venmo" && (
                        <div className="mb-3 label-left" style={{ marginTop: '10px' }}>
                          <label htmlFor="venmoNumber" className={`form-label ${classes.formLabel}`} style={{ padding: '5px' }}>
                            <strong>Venmo Number <span className='red'>*</span></strong>
                          </label>
                          <div className="input-group">
                            <div className="input-group-prepend phone-prepend">
                              <span className="input-group-text">+1</span>
                            </div>
                            <input
                              type="tel"
                              className={`form-control phone-input ${errors.venmoNumber ? 'is-invalid' : ''} ${classes.formInput}`}
                              id="venmoNumber"
                              placeholder="Enter your Venmo phone number"
                              {...register('venmoNumber', {
                                required: selectedButton === 'Venmo',
                                minLength: 14,
                                maxLength: 14,
                              })}
                              onChange={(e) => {
                                let inputValue = e.target.value.replace(/\D/g, '');

                                if (inputValue.length > 0) {
                                  inputValue = "(" + inputValue;
                                }
                                if (inputValue.length > 4) {
                                  inputValue = inputValue.slice(0, 4) + ") " + inputValue.slice(4);
                                }
                                if (inputValue.length > 9) {
                                  inputValue = inputValue.slice(0, 9) + "-" + inputValue.slice(9);
                                }

                                inputValue = inputValue.slice(0, 14);
                                e.target.value = inputValue;
                              }}
                            />
                          </div>
                          {errors.venmoNumber && (
                            <span className="invalid-feedback">
                              {errors.venmoNumber.type === 'required'
                                ? 'Venmo number is required'
                                : 'Please enter a valid phone number'}
                            </span>
                          )}
                        </div>
                      )}
                      <div className='with-spacing'>
                        <h3 className='product-upc'>RECEIPT UPLOAD</h3>
                        <MantineDropZone onFileUpload={handleFileUpload} />
                        {formSubmitted && uploadedFiles.length === 0 && (
                          <div style={{ marginTop: '5px' }}>
                            <span style={{ color: 'red', fontWeight: '700' }}>Please upload a file before submitting the form.</span>
                          </div>
                        )}
                      </div>
                      <div className="row col with-spacing">
                        <div className="form-check mb-3">
                          <input
                            type="checkbox"
                            className={`form-check-input ${errors.agreeToTerms ? 'is-invalid' : ''}`}
                            id="agreeToTerms"
                            {...register('agreeToTerms', { required: true })}
                          />
                          <label className="form-check-label" htmlFor="agreeToTerms">
                            I agree to the <a href="#">Terms and Conditions</a> and to receive emails that are relevant to my rebate.<span className='red'>*</span>
                          </label>
                        </div>
                      </div>
                      <div className='with-spacing'></div>
                      <div className='button-container'>
                        <button type="submit" className="half-width" id='review-button'>REVIEW & SUBMIT</button>
                        <button type="button" className="half-width" style={{ backgroundColor: '#f0f0f0' }} onClick={closeForm} data-dismiss="modal">CANCEL</button>
                      </div>
                    </div>
                  </form>
                </div>
              </Box>
            )}
            {showPreview && (
              <PreviewSubmission
                page={page}
                formData={getValues()}
                edit={handleEdit}
                uploadedFiles={uploadedFiles}
                paymentMethod={selectedButton}
                handleCancel={handleCancel}
                handleSuccess={handleSuccess}
                closePreview={closePreview}
                rebatesData={offer} // Pass the offer data
                clearForm={clearForm}
                setFormSubmitted={setFormSubmitted}
                closeForm={closeForm}
                setShowFailedPopUp={setShowFailedPopUp}
                setShowSuccessPopUp={setShowSuccessPopUp}
                onSuccess={onSuccess}
              />
            )}
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}

export default SubmissionForm;
